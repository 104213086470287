<template>
    <v-bottom-sheet v-model="sheet" content-class="rounded-t-lg">
        <!-- card -->
        <v-card class="rounded-t-lg">
            <!-- title -->
            <v-toolbar flat class="text-h6 text-uppercase">
                <v-spacer/>
                Salary Details
                <v-spacer/>
            </v-toolbar>

            <v-card-text class="px-9 pb-1">
                <!-- Received Salary -->
                <div class="mb-2">
                    <div class="d-flex justify-space-between align-center">
                        <span class="text-capitalize grey--text text--darken-3 text-body-1 font-weight-bold">
                            Received Salary
                        </span>
                        <span class="text-body-1 secondary--text">{{ salary.finalTotal || 0 }}</span>
                    </div>
                </div>

                <!-- Received Salary -->
                <div>
                    <div class="d-flex justify-space-between align-center">
                        <span class="text-capitalize grey--text text--darken-3 text-body-1 font-weight-bold">
                            Receive Date
                        </span>
                        <span class="text-body-1">{{ moment(salary.date) || 0 }}</span>
                    </div>
                </div>

                <v-divider class="my-4"/>

                <!-- Base Salary -->
                <div class="mb-2">
                    <div class="d-flex justify-space-between align-center">
                        <span class="text-capitalize grey--text text--darken-3 text-body-1">
                            Base Salary
                        </span>
                        <span class="text-body-1">{{ salary.baseSalary || 0 }}</span>
                    </div>
                </div>

                <!-- rewards -->
                <div class="mb-2">
                    <div class="d-flex justify-space-between align-center">
                        <span class="text-capitalize grey--text text--darken-3 text-body-1">
                            Rewards
                        </span>
                        <span class="text-body-1">{{ salary.rewards || 0 }}</span>
                    </div>
                </div>

                <!-- rebates -->
                <div class="mb-2">
                    <div class="d-flex justify-space-between align-center">
                        <span class="text-capitalize grey--text text--darken-3 text-body-1">
                            Rebates
                        </span>
                        <span class="text-body-1">{{ salary.rebates || 0 }}</span>
                    </div>
                </div>

                <!-- transportation -->
                <div class="mb-2">
                    <div class="d-flex justify-space-between align-center">
                        <span class="text-capitalize grey--text text--darken-3 text-body-1">
                            Transportation
                        </span>
                        <span class="text-body-1">{{ salary.transportation || 0 }}</span>
                    </div>
                </div>

                <!-- liability compensation -->
                <div class="mb-2">
                    <div class="d-flex justify-space-between align-center">
                        <span class="text-capitalize grey--text text--darken-3 text-body-1">
                            Liability Compensation
                        </span>
                        <span class="text-body-1">{{ salary.liabilityCompensation || 0 }}</span>
                    </div>
                </div>

                <!-- living expenses -->
                <div class="mb-2">
                    <div class="d-flex justify-space-between align-center">
                        <span class="text-capitalize grey--text text--darken-3 text-body-1">
                            Living Expenses
                        </span>
                        <span class="text-body-1">{{ salary.livingExpenses || 0 }}</span>
                    </div>
                </div>

                <!-- communications -->
                <div class="mb-2">
                    <div class="d-flex justify-space-between align-center">
                        <span class="text-capitalize grey--text text--darken-3 text-body-1">
                            Communications
                        </span>
                        <span class="text-body-1">{{ salary.communications || 0 }}</span>
                    </div>
                </div>

            </v-card-text>
            
            <v-card-actions class="pt-0">
                <v-spacer/>
                <v-btn
                    text
                    block
                    @click="sheet = false"
                    class="font-weight-bold"
                    color="grey darken-2"
                >
                    DONE
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-bottom-sheet>
</template>

<script>
export default {
	props: ['salary'],

	data() {
		return {
			sheet: false
		}
	},

	methods: {
		activeSheet() {
			this.sheet = true;
		},

		moment(date) {
			return window.moment(date).format('YYYY-MM-DD');
		}
	}
}
</script>

<style>

</style>