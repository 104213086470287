<template>
	<div id="visits-frequency">
		<!-- filters -->
		<v-card flat color="lightgray">
			<!-- date -->
			<v-card-text class="d-flex justify-space-between align-center py-2">
				<!-- date dialog -->
				<v-dialog
					ref="dialog"
					v-model="dialog"
					:return-value.sync="selectedMonth"
					persistent
					max-width="300px"
				>
					<!-- date text -->
					<template v-slot:activator="{ on, attrs }">
						<div
							v-bind="attrs"
							v-on="on"
							class="d-flex justify-space-between align-center primary--text"
						>
							<div class="d-flex align-center">
								<v-icon class="me-2" color="primary">mdi-calendar</v-icon>
								<span class="text-h5 me-2">{{moment(selectedMonth, 'YYYY-MM').format('MMMM')}}</span>
								<span class="text-subtitle-2 align-self-end">{{moment(selectedMonth, 'YYYY-MM').format('YYYY')}}</span>
							</div>
						</div>
					</template>

					<!-- dialog date picker -->
					<v-date-picker
						v-model="selectedMonth"
						full-width
						picker-date
						type="month"
						:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
					>
						<v-spacer></v-spacer>
						<v-btn
							text
							color="primary"
							@click="dialog = false"
						>
							cancel
						</v-btn>
						<v-btn
							text
							color="primary"
							@click="
								selectedMonth !== tableMonth
									? (() => {
										$refs.dialog.save(selectedMonth);
										selectedMonth = moment(selectedMonth, 'YYYY-MM').format('YYYY-MM');
										filter(Boolean(entityName), Boolean(regionId));
									})()
									: dialog = false;
							"
						>
							OK
						</v-btn>
					</v-date-picker>
				</v-dialog>

				<!-- today button -->
				<v-btn
					v-if="false"
					text
					small
					color="primary"
					@click="
						selectedMonth !== tableMonth
							? (() => {
								selectedMonth = moment().format('YYYY-MM');
								filter(entities.length === 0, true);
							})()
							: null
					"
				>
					current month
				</v-btn>
			</v-card-text>
		</v-card>

		<v-row dense class="ma-0 px-4">
			<!-- entity name -->
			<v-col cols="6">
				<v-text-field
					v-model="entityName"
					label="Entity name"
					dense
					hide-details
					outlined
					@keypress.down="filter(true, false)"
					@change="$event ? filter(true, false) : null"
				/>
			</v-col>

			<!-- region -->
			<v-col cols="6">
				<v-autocomplete
					v-model="regionId"
					label="Region"
					:items="regions"
					item-text="name"
					item-value="id"
					dense
					hide-details
					outlined
					@change="filter(true, false)"
				/>
			</v-col>
		</v-row>

		<!-- table -->
		<v-container>
			<v-data-table
				ref="table"
				:headers="headers"
				:items="loading ? [] : tableItems"
				:items-per-page="-1"
				:height="$vuetify.breakpoint.height - 235"
				:loading="loading"
				fixed-header
				hide-default-footer
				mobile-breakpoint="0"
				disable-sort
				dense
				class="elevation-3"
			>
				<template
					v-for="i in moment(tableMonth, 'YYYY-MM').daysInMonth()"
					v-slot:[`item.day${i}`]="{ item, value, header }"
				>
					<div
						:key="i"
						class="state-cell"
						:class="[
							{
								'success': value === visitStates.done,
								'red': value === visitStates.missed,
								'orange': value === visitStates.outOfPlan,
							}
						]"
					>
						{{item.daysCount ? item.daysCount[header.value] : null}}
					</div>
				</template>
			</v-data-table>
		</v-container>
	</div>
</template>

<script>
import { entityType } from '@/helpers/enums';
import moment from 'moment';
import { mapState } from 'vuex';
export default {
	name: 'VisitsFrequency',

	data() {
		return {
			loading: false,
			dialog: false,
			selectedMonth: moment().format('YYYY-MM'),
			tableMonth: moment().format('YYYY-MM'),

			// filter data
			entityName: null,
			regionId: null,

			// data
			tableItems: [],
			tempVisits: [],
			visitStates: {
				done: 1,
				outOfPlan: 2,
				missed: 3,
			}
		}
	},

	computed: {
		...mapState({
			regions: state => state.regions.regions,
			entities: state => state.entities.entities,
		}),

		headers() {
			const days = [];

			for (let i = 1; i <= moment(this.tableMonth, 'YYYY-MM').daysInMonth(); i++) {
				days.push({
					text: moment(this.tableMonth + `-${i.toString().padStart(2, '0')}`).format('ddd DD').replace(/ /g, '\n'),
					name: 'day' + i,
					value: 'day' + i,
					class: 'px-0 text-pre-wrap table-head',
					cellClass: 'px-0',
					align: 'center',
					divider: true,
					width: 40
				})
			}
			const headers = [
				{
					text: 'Entity',
					value: 'entityName',
					class: 'px-1 fixed-col fixed-col--head',
					cellClass: 'px-1 grey--text text--darken-2 fixed-col font-weight-bold',
					align: 'center',
					divider: true,
					width: 120
				},
				...days
			];
			return headers;
		},
	},

	methods: {
		filter(updateEntities = false, updateVisits = false, isFirstLoad = false) {
			this.tableMonth = this.selectedMonth;

			this.loading = true;
			return Promise.all([
				updateEntities
					? this.$store.dispatch('entities/fetchAll', {
						type: entityType.doctor,
						name: this.entityName,
						regionId: this.regionId,
					})
					: null,
				!this.tempVisits.length || updateVisits
					? this.$store.dispatch('visits/fetchAllDueDates', {
						fromDate: moment(this.selectedMonth, 'YYYY-MM').startOf('months').format('YYYY-MM-DD'),
						toDate: moment(this.selectedMonth, 'YYYY-MM').endOf('months').format('YYYY-MM-DD'),
					})
					: null
			]).then((data) => {
				const items = [];
				let entities = [];

				entities = data[0] || this.entities;
				if (!this.tempVisits.length || updateVisits) this.tempVisits = data[1];
				const visits = this.tempVisits;
				
				// building table items
				entities.forEach(entity => {
					const entityVisits = visits.filter(c => c.entityId === entity.id);
					if (entityVisits.length) {
						const obj = {};
						const daysKeysObj = {};
						
						entityVisits.forEach(visit => {
							const day = 'day' + moment(visit.createdAt).format('D');

							// get visits count for each day 
							if (day in daysKeysObj) {
								if (!obj.daysCount) obj.daysCount = {};
								obj.daysCount[day] = (obj.daysCount[day] || 1) + 1;
							}

							obj.entityName = visit.entity.name;
							daysKeysObj[day] = this.visitStates.done;
						})
						items.push({ ...obj, ...daysKeysObj });
					} else {
						items.push({ entityName: entity.name })
					}
				});

				this.tableItems = items;
			}).finally(() => {
				if (!isFirstLoad) this.loading = false;
			})
		},
		moment
	},

	created() {
		this.loading = true;
		Promise.all([
			this.$store.dispatch('visits/fetchAllDueDates', {
				fromDate: moment(this.selectedMonth, 'YYYY-MM').startOf('months').format('YYYY-MM-DD'),
				toDate: moment(this.selectedMonth, 'YYYY-MM').endOf('months').format('YYYY-MM-DD'),
			}),
			this.$store.dispatch('regions/fetchAll'),
		]).finally(() => {
			this.loading = false;
		})
	}
}
</script>

<style lang="scss">
#visits-frequency {
	.state-cell {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
	}
	.fixed-col {
		position: sticky;
		left: 0;
		background: white;
		font-size: 12px;
	
		&--head {
			font-size: inherit;
			z-index: 3;
		}
	}
	.table-head {
		line-height: 14px;
	}
	.v-data-table tr:nth-of-type(2n) {
		&, & .fixed-col {
			background: #eee;
		}
	}
}
</style>