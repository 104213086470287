import Login from '../pages/Login'
import Visits from '../pages/Visits'
import Plans from '../pages/Plans'
import Samples from '../pages/Samples'
import Customers from '../pages/Customers'
import Products from '../pages/Products'
import Profile from '../pages/Profile'
import VisitsFrequency from '../pages/VisitsFrequency'
import Exams from '../pages/Exams'

export default [
	{
		path: '/',
		name: 'visits',
		component: Visits
	},
	{
		path: '/plans',
		name: 'plans',
		component: Plans
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/samples',
		name: 'samples',
		component: Samples
	},
	{
		path: '/customers',
		name: 'customers',
		component: Customers
	},
	{
		path: '/products',
		name: 'products',
		component: Products
	},
	{
		path: '/profile',
		name: 'profile',
		component: Profile
	},
	{
		path: '/visits-frequency',
		name: 'visits-frequency',
		component: VisitsFrequency
	},
	{
		path: '/exams',
		name: 'exams',
		component: Exams
	},
]