<template>
    <div>
        <!-- visit dialog is declared here to use it as ref -->
        <visit-dialog ref="VisitDialog" @on-save="fetchVisits"/>
        
        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog ref="DeleteDialog" @on-delete="fetchVisits">
            <template v-slot:default>
                <p class="mb-0 text-body-1">
                    Are you sure you want to <span class="red--text">DELETE</span> the visit ?
                </p>
            </template>
        </delete-dialog>

        <view-visit-sheet ref="ViewVisitSheet"/>

        <!-- add visit -->
        <v-fab-transition>
            <v-btn
                color="primary"
                class="mb-15"
                fab
                fixed
                bottom
                right
                v-show="isVisibleAdd"
                @click="$refs.VisitDialog.addVisit(selectedFilter, date)"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>

        <!-- filter -->
        <v-card flat color="lightgray">
            <v-card-text class="d-flex flex-wrap justify-space-between align-center">
                <span class="text-body-1 me-6">Filters</span>
                <v-chip-group
                    v-model="selectedFilter"
                    show-arrows
                    center-active
                    color="secondary"
                    class="flex-grow-1"
                >
                    <v-chip filter small outlined :value="1" class="flex-grow-1 justify-center">
                        Doctor
                    </v-chip>
                    <v-chip filter small outlined :value="2" class="flex-grow-1 justify-center">
                        Pharmacy
                    </v-chip>
                    <v-chip filter small outlined :value="0" class="flex-grow-1 justify-center">
                        Center
                    </v-chip>
                </v-chip-group>
            </v-card-text>

            <!-- date -->
            <v-card-text class="d-flex justify-space-between align-center pt-0">
                <!-- date dialog -->
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="selectedMonth"
                    persistent
                    max-width="300px"
                >
                    <!-- date text -->
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                            class="d-flex justify-space-between align-center primary--text"
                        >
                            <div class="d-flex align-center">
                                <v-icon class="me-2" color="primary">mdi-calendar</v-icon>
                                <span class="text-h5 me-2">{{moment(date, 'YYYY-MM-DD').format('MMMM')}}</span>
                                <span class="text-subtitle-2 align-self-end">{{moment(date, 'YYYY-MM-DD').format('YYYY')}}</span>
                            </div>
                        </div>
                    </template>
                    <!-- dialog date picker -->
                    <v-date-picker
                        v-model="selectedMonth"
                        full-width
                        picker-date
						type="month"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="
								$refs.dialog.save(selectedMonth);
								date = moment(selectedMonth, 'YYYY-MM').format('YYYY-MM-DD');
								fetchVisits();
							"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>

				<!-- today button -->
				<v-btn
					text
					small
					color="primary"
					@click="goToTodayDate"
				>
					Today
				</v-btn>
            </v-card-text>
        </v-card>

		<!-- days picker -->
		<v-month-days v-model="date" :month="selectedMonth" @input="fetchVisits"/>
        
        <v-container class="visit-card-container">
            <div v-if="!loading && filteredVisits.length > 0">
                <visit-card
                  v-for="visit in filteredVisits"
                  :key="visit.id"
                  :visit="visit"
                  class="mb-5"
                  @on-edit-prevent="editPrevent"
                  @on-swipe-right="$refs.DeleteDialog.activeDialog(visit.id, 'visits')"
                  @on-click="$refs.ViewVisitSheet.activeSheet(visit.id)"
                  :disabled="disableCard"
                />
            </div>
            <div
              v-else-if="!loading && filteredVisits.length === 0"
              class="d-flex flex-column justify-center align-center"
              style="height: 45vh"
            >
                <v-icon x-large class="mb-3">mdi-flask-remove</v-icon>
                <span class="grey--text text--darken-2">NO VISITS TO SHOW</span>
            </div>

            <!-- loader -->
            <loader v-if="loading"/>
        </v-container>
    </div>
</template>

<script>
import DeleteDialog from '../components/DeleteDialog'
import Loader from '../components/Loader'
import VisitDialog from '../components/VisitDialog'
import VisitCard from '../components/VisitCard'
import ViewVisitSheet from '../components/ViewVisitSheet'
import VMonthDays from '@/components/VMonthDays.vue'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
	data() {
		return {
			modal: false,
			loading: true,
			isVisibleAdd: false,
			selectedFilter: null,
			selectedMonth: moment().format('YYYY-MM'),
			date: moment().format('YYYY-MM-DD'),
			disableCard: false
		}
	},

	methods: {
		moment,
		
		fetchVisits() {
			// disabling visit card
			this.disableCard = true;
            
			// Active nav loading
			this.$emit('loading:active');
			this.$store.dispatch('visits/fetchAllData', { day: this.date })
				.finally(() => {
					this.$emit('loading:disable');
					this.disableCard = false;
				})
		},

		editPrevent(message) {
			this.$eventBus.$emit('show-snackbar', message, 'info');
		},

		goToTodayDate() {
			const todayDate = moment().format('YYYY-MM-DD');
			if (this.date !== todayDate) {
				this.selectedMonth = moment().format('YYYY-MM');
				this.date = todayDate;
				this.fetchVisits();
			}
		}
	},

	computed: {
		filteredVisits() {
			if (this.selectedFilter !== null && this.selectedFilter !== undefined) {
				return this.visits.filter(c => c.entity.entityType === this.selectedFilter);
			} else {
				return this.visits;
			}
		},

		...mapState({
			visits: state => state.visits.visits
		})
	},

	components: {
		DeleteDialog,
		Loader,
		VisitDialog,
		VisitCard,
		ViewVisitSheet,
		VMonthDays
	},

	mounted() {
		this.loading = true;
		Promise.all([
			this.$store.dispatch('visits/fetchAllData', { day: moment().format('YYYY-MM-DD') }),
			this.$store.dispatch('regions/fetchAll'),
			this.$store.dispatch('materials/fetchAll', {}),
			this.$store.dispatch('materialsTransactions/fetchAll'),
			this.$store.dispatch('materialForms/fetchAll'),
		])
			.finally(() => {
				setTimeout(() => {
					this.isVisibleAdd = true;
				}, 200);
				this.loading = false;
			})
	},

	beforeDestroy() {
		this.$store.dispatch('visits/reset');
		this.$store.dispatch('regions/reset');
		this.$store.dispatch('materials/reset');
		this.$store.dispatch('materialForms/reset');
	}
}
</script>

<style lang="scss" scoped>
.visit-card-container{
    overflow-x: hidden;
}
</style>