import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		target: {},
		salary: {},
		leaves: {},
		review: {}
	},

	actions: {
		target({ commit }) {
			return window.axios.get('/targets/mine')
				.then((response) => {
					commit(types.STORE_TARGET, response.data);
					return response.data;
				})
		},

		salary({ commit }) {
			return window.axios.get('/salaries/mine/latest')
				.then((response) => {
					commit(types.STORE_SALARY, response.data);
					return response.data;
				})
		},

		review({ commit }) {
			return window.axios.get('/reviews/mine')
				.then((response) => {
					commit(types.STORE_REVIEW, response.data);
					return response.data;
				})
		},

		leaves({ commit }, { employeeId }) {
			return window.axios.get(`/leaves/${employeeId}`).then(response => {
				commit(types.STORE_LEAVES, response.data);
				return response.data;
			})
		},

		creatDayOff({ commit }, { date, reason, type, days }) {
			return window.axios.post('/requests/daysoff', { date, reason, type, days });
		},

		reset({ commit }) {
			commit(types.RESET_PROFILE);
		}

	},

	mutations: {
		[types.STORE_TARGET](state, target) {
			state.target = target;
		},

		[types.STORE_SALARY](state, salary) {
			state.salary = salary;
		},

		[types.STORE_LEAVES](state, leaves) {
			state.leaves = leaves;
		},

		[types.STORE_REVIEW](state, review) {
			state.review = review;
		},

		[types.RESET_PROFILE](state) {
			state.target = {};
			state.salary = {};
			state.review = {};
		},
	}
}