<template>
	<div id="plans" class="pb-sm-2">
		<v-fab-transition>
			<!-- v-if="
				!loading &&
				moment(selectedMonth, 'YYYY-MM').diff(moment().format('YYYY-MM'), 'months') >= 0 &&
				moment(selectedMonth, 'YYYY-MM').diff(moment(activePlan.fromDate).format('YYYY-MM'), 'months') <= 0
			" -->
			<v-btn
				v-if="
					!loading &&
					moment(selectedMonth, 'YYYY-MM').diff(moment().format('YYYY-MM'), 'months') > 0 &&
					moment(selectedMonth, 'YYYY-MM').diff(moment(activePlan.fromDate).format('YYYY-MM'), 'months') === 0
				"
				fab
				fixed
				bottom
				right
				direction="left"
				color="primary"
				class="mb-15"
				@click="planningVisitSheet = true"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>

			<v-speed-dial
				v-if="0"
				v-model="buttonsOptions"
				fixed
				bottom
				right
				direction="left"
				transition="slide-x-reverse-transition"
				class="mb-15"
			>
				<template v-slot:activator>
					<v-btn
						v-if="!loading"
						v-model="buttonsOptions"
						fab
						color="primary"
					>
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>

				<v-btn
					fab
					color="success"
					small
					@click="planningVisitSheet = true"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
				<v-btn
					fab
					small
					color="deep-purple white--text"
					:disabled="!planningVisits.length"
					@click="''"
				>
					<v-icon>mdi-content-save</v-icon>
				</v-btn>
			</v-speed-dial>
		</v-fab-transition>
		
		<!-- delete dialog declaered here to use it as ref -->
        <delete-dialog ref="DeleteDialog" @on-delete="fetchPlannedVisits">
            <template v-slot:default>
                <p class="mb-0 text-body-1">
                    Are you sure you want to <span class="red--text">DELETE</span> the planned visit ?
                </p>
            </template>
        </delete-dialog>

		<!-- add sheet -->
		<v-bottom-sheet v-model="planningVisitSheet" persistent>
			<v-card>
				<v-toolbar dark flat color="primary" class="text-uppercase">
					<v-spacer/>
					{{ !plannedVisitId ? 'Add' : 'Edit' }} planned visit
					<v-spacer/>
					<v-toolbar-items text>
						<v-btn small text @click="planningVisitSheet = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-form @submit.prevent="submit" ref="form" :disabled="submitLoading">
                    <!-- radio buttons -->
					<v-radio-group
						v-model="sheetData.directoryType"
						mandatory
						row
						hide-details
						@change="sheetData.regionId = sheetData.entityId = null"
					>
						<v-radio
							label="Doctors"
							:value="entityType.doctor"
							class="mx-auto"
							color="success"
						/>
						<v-radio
							label="Pharmacies"
							:value="entityType.pharmacy"
							class="mx-auto"
							color="success"
						/>
						<v-radio
							label="Centers"
							:value="entityType.center"
							class="mx-auto"
							color="success"
						/>
					</v-radio-group>

                    <!-- card content -->
                    <v-card-text class="pb-0">
						<v-row dense>
							<!-- region -->
							<v-col md="6" cols="12">
								<v-autocomplete
									v-model="sheetData.regionId"
									:items="regions"
									item-text="name"
									item-value="id"
									label="region"
									:rules="rules.required"
									hide-details="auto"
									@change="fetchEntities(); sheetData.entityId = null"
								></v-autocomplete>
							</v-col>

							<!-- entity -->
							<v-col md="6" cols="12">
								<v-autocomplete
									v-model="sheetData.entityId"
									:items="entities"
									item-text="name"
									item-value="id"
									:multiple="!plannedVisitId"
									label="Customer Name"
									chips
									small-chips
									deletable-chips
									:rules="rules.required"
									hide-details="auto"
									:loading="entitiesLoading"
									:disabled="entitiesLoading || !sheetData.regionId || submitLoading"
								></v-autocomplete>
							</v-col>
							
							<!-- materials -->
							<template v-if="useMaterialsPlan">
								<v-col cols="12">
									<span class="text-body-1">Materials</span>
									<v-btn
										icon
										@click="sheetData.plannedMaterials.push({
											id: sheetData.plannedMaterials.length,
											materialId: null,
											amount: null
										})"
									>
										<v-icon>mdi-plus-circle-outline</v-icon>
									</v-btn>
								</v-col>
								<v-col v-for="(item, index) in sheetData.plannedMaterials" :key="index" cols="12">
									<v-row dense align="center">
										<!-- material -->
										<v-col cols="7">
											<v-autocomplete
												v-model="item.materialId"
												:items="allMaterials"
												label="Material"
												item-text="name"
												item-value="id"
												dense
												hide-details
												:rules="rules.required"
											/>
										</v-col>

										<!-- amount -->
										<v-col cols="3">
											<v-text-field
												v-model.number="item.amount"
												label="amount"
												type="number"
												min="0"
												hide-spin-buttons
												dense
												hide-details
												:rules="rules.requiredAsNumber"
											/>
										</v-col>

										<!-- remove -->
										<v-col cols="auto">
											<v-btn
												icon
												@click="
													sheetData.plannedMaterials.splice(index, 1);
													sheetData.plannedMaterials.forEach((c, i) => {
														if (c.id >= item.id) sheetData.plannedMaterials[i].id--;
													})
												"
											>
												<v-icon>mdi-minus-circle-outline</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</v-col>
							</template>

							<!-- date -->
							<v-col md="6" cols="12">
								<v-menu
									v-model="dateMenu"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="sheetData.date"
											label="Date"
											prepend-icon="mdi-calendar"
											:rules="rules.required"
											hide-details="auto"
											readonly
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>

									<v-date-picker
										v-model="sheetData.date"
										:min="moment().add(1, 'months').startOf('months').format('YYYY-MM-DD')"
										:max="moment().add(1, 'months').endOf('months').format('YYYY-MM-DD')"
										@input="dateMenu = false"
									></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
						<v-btn
							color="success"
							text
							type="submit"
							:loading="submitLoading"
							:disabled="submitLoading"
						>
							Save
						</v-btn>
						<v-btn color="grey darken-1" text @click="planningVisitSheet = false">
							Close
						</v-btn>
					</v-card-actions>
                </v-form>
			</v-card>
		</v-bottom-sheet>
		
		<!-- filter -->
        <v-card flat color="lightgray">
            <!-- date -->
            <v-card-text class="d-flex justify-space-between align-center">
                <!-- date dialog -->
                <v-dialog
					v-model="dialog"
                    ref="dialog"
                    :return-value.sync="selectedMonth"
                    persistent
                    max-width="300px"
                >
                    <!-- date text -->
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                            class="d-flex justify-space-between align-center primary--text "
                        >
                            <div class="d-flex align-center">
                                <v-icon class="me-2" color="primary">mdi-calendar</v-icon>
                                <span class="text-h5 me-2">{{moment(selectedMonth).format('MMMM')}}</span>
								<span class="text-subtitle-2 align-self-end">{{moment(selectedMonth).format('YYYY')}}</span>
                            </div>
                        </div>
                    </template>

                    <!-- dialog date picker -->
                    <v-date-picker
                        v-model="selectedMonth"
                        full-width
                        picker-date
						type="month"
                        :max="moment().add(1, 'month').format('YYYY-MM-DD')"
						@change="selectedDay = undefined"
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="dialog = false"
                        >
                            cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(selectedMonth); updateDate();"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>

				<!-- today button -->
				<v-btn
					text
					small
					color="primary"
					@click="goToTodayDate"
				>
					Today
				</v-btn>
            </v-card-text>
        </v-card>

		<!-- date picker -->
		<div class="d-flex align-center mb-4">
			<v-btn
				depressed
				min-width="0"
				height="48"
				class="mx-2 py-0 px-2 rounded-sm transparent"
				:class="{ secondary: !selectedDay }"
				@click="selectedDay = undefined"
			>All</v-btn>

			<v-month-days
				v-model="selectedDay"
				:month="selectedMonth"
				:max-width="$vuetify.breakpoint.width - (isTouchDevice ? 52 : 70)"
			></v-month-days>
		</div>

		<!-- cards -->
		<loader v-if="loading"/>
		<v-row v-else :no-gutters="$vuetify.breakpoint.xs" dense class="mx-2">
			<v-col
				v-for="(plannedVisit, index) in filteredPlanningVisits"
				:key="plannedVisit.id"
				lg="3" md="4" sm="6" cols="12"
				class="planned-visit__container mb-sm-0 mb-3"
				:class="{ 'mb-16': index === filteredPlanningVisits.length - 1 }"
			>
				<!-- deleteFab -->
				<v-fab-transition>
					<v-btn
						v-if="deleteButtonFab && plannedVisitId === plannedVisit.id"
						fab
						absolute
						left
						dark
						small
						text
						:width="14"
						color="red darken-2"
						class="planned-visit__action-btn"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</v-fab-transition>

				<v-card
					v-touch="
						isPlannedVisitOutOfActivePlan(plannedVisit.id) && !plannedVisit.isOutPlan
						? {
							move: (e) => { plannedVisitId = plannedVisit.id; touchMove(e); },
							end: (e) => { endOffset(e) }
						}
						: {}
					"
					elevation="4"
					class="planned-visit__card"
					:style="
						(deleteButtonFab || editButtonFab) && plannedVisitId === plannedVisit.id
							? { transform: `translateX(${touchSwipeOffset}px)` }
							: null
					"
				>
					<v-card-text class="d-flex align-center pa-2">
						<div
							class="white--text rounded py-2 px-3 text-capitalize"
							:class="[
								{ 'success': plannedVisit.isVisited },
								plannedVisit.isOutPlan
									? 'orange darken-1'
									: !plannedVisit.isVisited
										? moment(plannedVisit.date).diff(moment(), 'days') < 0
											? 'red lighten-1'
											: 'grey darken-2'
										: null
							]"
						>
							{{getEntityNameByType(plannedVisit.entity.entityType)}}
						</div>
						<p class="ms-4 mb-0">{{plannedVisit.entity.name}}</p>
						<v-spacer/>
						<span class="primary--text pe-1">{{plannedVisit.date}}</span>

						<!-- delete button for planning state -->
						<template
							v-if="
								!isTouchDevice &&
								isPlannedVisitOutOfActivePlan(plannedVisit.id) &&
								!plannedVisit.isOutPlan
							"
						>
							<v-btn icon @click="plannedVisitId = plannedVisit.id;  fillDateToEdit()">
								<v-icon color="success">mdi-pencil</v-icon>
							</v-btn>
							<v-btn
								icon
								@click="
									plannedVisitId = plannedVisit.id;
									$refs.DeleteDialog.activeDialog(plannedVisitId, 'plannedVisits')
								"
							>
								<v-icon color="red lighten-1">mdi-delete</v-icon>
							</v-btn>
						</template>
						<v-btn
							v-if="false && planningVisit.isInPlanningState"
							icon
							@click="removePlanningVisit(planningVisit.id)"
						>
							<v-icon color="red lighten-1">mdi-delete</v-icon>
						</v-btn>
					</v-card-text>
				</v-card>

				<!-- EditFab -->
				<v-fab-transition>
					<v-btn
						v-if="editButtonFab && plannedVisitId === plannedVisit.id"
						fab
						absolute
						right
						dark
						small
						text
						:width="14"
						color="success darken-2"
						class="planned-visit__action-btn"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</v-fab-transition>
			</v-col>

			<v-col
				v-if="!filteredPlanningVisits.length"
				cols="12"
				class="d-flex flex-column justify-center align-center grey--text text-body-1"
				style="height: 45vh"
			>
				<v-icon x-large class="mb-3">mdi-alert-circle-outline</v-icon>
				NO PLANNED VISITS FOR THIS DAY
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import DeleteDialog from '../components/DeleteDialog'
import VMonthDays from '@/components/VMonthDays.vue';
import { mapGetters, mapState } from 'vuex';
import { entityType } from '@/helpers/enums'
import { getEntityNameByType, isTouchDevice } from '@/helpers/functions'
import rules from '@/helpers/validation rules';
import moment from 'moment';
import { useMaterialsPlan } from '@/configs/general.configs'

export default {
	name: 'Plans',

	components: {
		DeleteDialog,
		VMonthDays,
		Loader
	},

	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,
		entitiesLoading: false,

		buttonsOptions: false,
		dialog: false,
		planningVisitSheet: false,

		dateMenu: null,
		selectedDay: moment().format('YYYY-MM-DD'),
		selectedMonth: moment().format('YYYY-MM'),

		rules,
		entityType,
		entities: [],

		// configs
		useMaterialsPlan,

		// out of plan visits
		notPlannedVisits: [],

		// swipe card
		plannedVisitId: null,
		touchSwipeOffset: 0,
		deleteButtonFab: false,
		editButtonFab: false,

		// data
		planningVisitsCounter: 0,
		planningVisits: [],
		sheetData: {
			directoryType: null,
			regionId: null,
			entityId: null,
			date: moment().format('YYYY-MM-DD'),
			plannedMaterials: [],
		}
	}),

	watch: {
		/*
		planningVisits: {
			handler: function (newVal) {
				localStorage.setItem('planning-visits', JSON.stringify(newVal));
			},
			deep: true
		},
		*/

		planningVisitSheet(newVal) {
			if (!newVal) {
				if (this.$refs.form) this.$refs.form.resetValidation();
				this.sheetData.directoryType = null;
				this.sheetData.regionId = null;
				this.sheetData.entityId = null;
				this.sheetData.plannedMaterials = [];

				this.plannedVisitId = null;
			} else {
				this.sheetData.date = this.selectedDay || moment(this.selectedMonth, 'YYYY-MM').format('YYYY-MM-DD');
			}
		}
	},

	computed: {
		...mapState({
			self: state => state.auth.self,
			allMaterials: state => state.materials.materials,
			plans: state => state.plans.plans,
			plannedVisits: state => state.plannedVisits.plannedVisits,
			activePlan: state => state.plans.activePlan,
			regions: state => state.regions.regions,
		}),

		...mapGetters({
			getEntityById: 'entities/getEntityById',
			getPlanById: 'plans/getPlanById',
			getPlannedVisitById: 'plannedVisits/getPlannedVisitById',
		}),

		filteredPlanningVisits() {
			return [
				...this.plannedVisits.filter(c => {
					// filter planned visits due to plan
					const plan = this.getPlanById(c.visitPlanId);
					return moment(c.date).isBetween(plan.fromDate, plan.toDate, 'days', '[]');
				}).map(c => ({ ...c, date: moment(c.date).format('YYYY-MM-DD') })),

				...this.notPlannedVisits
			].filter(c => {
				if (!this.selectedDay) return c.date.includes(this.selectedMonth);
				else return c.date === this.selectedDay;
			}).sort((a, b) => {
				const diff = moment(a.date).diff(b.date, 'days');
				if (diff > 0) return -1;
				else if (diff < 0) return 1;
				return 0;
			});
		},

		/*
		todayDateText() {
			if (!this.selectedDay) return 'All';
			const todayDate = moment().format('YYYY-MM-DD');
			return todayDate === this.selectedDay ? 'Today' : moment(this.selectedDay).format('ddd');
		},
		*/

		isTouchDevice
	},

	methods: {
		moment,
		getEntityNameByType,

		fetchEntities() {
			this.entitiesLoading = true;
			
			this.$store.dispatch('entities/fetchAll', {
				type: this.sheetData.directoryType,
				regionId: this.sheetData.regionId
			}).then(data => {
				this.entities = data;
			}).finally(() => {
				this.entitiesLoading = false;
			})
		},

		updateDate() {
			this.$store.dispatch('plannedVisits/clear')
			this.loading = true;
			Promise.all([
				this.fetchVisits(true),
				this.fetchPlannedVisits(true),
			]).finally(() => {
				this.loading = false;
			})
		},

		fetchPlannedVisits(isFirsLoad = false) {
			this.loading = true;
			return this.$store.dispatch('plannedVisits/fetchAll', {
				fromDate: moment(this.selectedMonth, 'YYYY-MM').format('YYYY-MM-DD')
			}).finally(() => {
				if (!isFirsLoad) this.loading = false;
			})
		},

		fillDateToEdit() {
			// FIXME remove if condition comment to activate edit
			if (this.plannedVisitId) return;

			const plannedVisitData = this.getPlannedVisitById(this.plannedVisitId);
			this.sheetData.date = moment(plannedVisitData.date).format('YYYY-MM-DD');
			this.sheetData.directoryType = plannedVisitData.entity.entityType;
			this.sheetData.entityId =
				this.plannedVisitId ? plannedVisitData.entity.entityId : [plannedVisitData.entity.entityId];

			if (useMaterialsPlan && plannedVisitData.plannedVisitMaterials) {
				plannedVisitData.plannedVisitMaterials.forEach((c, i) => {
					this.sheetData.plannedMaterials.push({
						id: i,
						materialId: c.materialId,
						amount: c.amount,
					})
				})
			}
			
			this.planningVisitSheet = true;
		},

		// async submit() {
		submit() {
			if (this.$refs.form.validate()) {
				this.submitLoading = true;
				const actionName = this.plannedVisitId ? 'update' : 'create';

				/* const plan = this.plans.find(c => moment(c.fromDate).format('YYYY-MM') === this.selectedMonth);
				let visitPlanId = null;
				
				if (plan) {
					visitPlanId = plan.id;
				} else {
					await this.$store.dispatch('plans/create', {
						employeeId: this.self.employeeId,
						fromDate: moment().startOf('months').format('YYYY-MM-DD'),
						toDate: moment().endOf('months').format('YYYY-MM-DD')
					}).then((id) => {
						// visitPlanId = id;
						this.$store.dispatch('plans/fetchAll');
					})
				} */
				
				const promises = [];
				[this.sheetData.entityId].flat().forEach(entityId => {
					promises.push(
						this.$store.dispatch(`plannedVisits/${actionName}`, {
							id: this.plannedVisitId,
							// FIXME use next line instead, NOT (const visitPlanId)
							visitPlanId: this.activePlan.id,
							// visitPlanId: visitPlanId,
							entityId: entityId,
							date: this.sheetData.date,
							materialsAmount:
								useMaterialsPlan
									? this.sheetData.plannedMaterials.map(c => ({
										materialId: c.materialId,
										amount: c.amount,
									})).reduce((res, a) => {
										const index = res.findIndex(c => a.materialId === c.materialId);
										if (index === -1) res.push(a);
										else res[index].amount += a.amount;
										return res;
									}, []) 
									: []
						})
					)
				})

				Promise.all(promises).then(() => {
					this.planningVisitSheet = false;
					this.fetchPlannedVisits();
				}).finally(() => {
					this.submitLoading = false;
				})
			} 
		},

		// swipe card methods
		isPlannedVisitOutOfActivePlan(visitId) {
			const plannedVisitData = this.getPlannedVisitById(visitId);
			if (Object.keys(this.activePlan).length && plannedVisitData) {
				return moment(plannedVisitData.date)
					.isBetween(this.activePlan.fromDate, this.activePlan.toDate, 'days', '[]')
			} else {
				return false
			} 
		},

		touchMove(event) {
			const offset = event.touchmoveX - event.touchstartX;
			this.touchSwipeOffset = offset > -50 && offset < 50 ? offset : Math.sign(offset) * 50;

			if (this.touchSwipeOffset === 50) {
				this.deleteButtonFab = true;
				this.editButtonFab = false;
			} else if (this.touchSwipeOffset === -50) {
				this.deleteButtonFab = false;
				this.editButtonFab = true;
			} else {
				this.deleteButtonFab = false;
				this.editButtonFab = false;
			}
		},
		endOffset() {
			if (this.touchSwipeOffset === 50) {
				this.$refs.DeleteDialog.activeDialog(this.plannedVisitId, 'plannedVisits');
			} else if (this.touchSwipeOffset === -50) {
				this.fillDateToEdit();
			}
			this.deleteButtonFab = false;
			this.editButtonFab = false;
		},

		/*
		addPlanningVisit() {
			if (this.$refs.form.validate()) {
				this.planningVisits.push({
					id: ++this.planningVisitsCounter,
					isInPlanningState: true,
					regionId: this.sheetData.regionId,
					entity: {
						id: this.sheetData.entityId,
						entityType: this.sheetData.directoryType,
						name: this.entities.find(c => c.id === this.sheetData.entityId).name,
					},
					date: this.sheetData.date,
				});

				this.planningVisitSheet = false;
			}
		},

		removePlanningVisit(id) {
			const index = this.planningVisits.findIndex(c => c.id === id);
			this.planningVisits.splice(index, 1);
			this.planningVisits.forEach((planningVisit, i) => {
				if (planningVisit > i) this.planningVisits[i].id -= 1;
			})
			this.planningVisitsCounter--;
		},
		*/
		fetchVisits(isFirsLoad) {
			this.loading = true;
			return this.$store.dispatch('visits/fetchAllDueDates', {
				fromDate: moment(this.selectedMonth, 'YYYY-MM').startOf('months').format('YYYY-MM-DD'),
				toDate: moment(this.selectedMonth, 'YYYY-MM').endOf('months').format('YYYY-MM-DD')
			}).then(data => {
				const getDate = (date) => moment(date).format('YYYY-MM-DD');

				const minifiedPlannedVisits =
					this.plannedVisits.map(c => ({ date: getDate(c.date), entityId: c.entityId }));
				
				this.notPlannedVisits =
					data.filter(c => 
						minifiedPlannedVisits.findIndex(e =>
							getDate(c.createdAt) === e.date &&
							c.entityId !== e.entityId
						) >= 0
					).map(c => ({ ...c, date: getDate(c.createdAt), isOutPlan: true }))
			}).finally(() => {
				if (!isFirsLoad) this.loading = false;
			})
		},

		goToTodayDate() {
			const currentMonth = moment().format('YYYY-MM');
			this.selectedDay = moment().format('YYYY-MM-DD');

			if (this.selectedMonth !== currentMonth) {
				this.selectedMonth = moment().format('YYYY-MM');

				this.loading = false;
				Promise.all([
					this.fetchPlannedVisits(true),
					this.fetchVisits(true)
				]).finally(() => {
					this.loading = false;
				})
			}
		}
	},

	created() {
		/*
		if ('planning-visits' in localStorage) {
			this.planningVisits = JSON.parse(localStorage.getItem('planning-visits'));
			this.planningVisitsCounter = this.planningVisits.length;
		} else {
			promises.push(this.$store.dispatch('plans/fetchAll'));
		}
		*/
		let isDateInActivePlanRange;
		this.loading = true;
		Promise.all([
			this.fetchPlannedVisits(true),
			this.fetchVisits(true),
			this.$store.dispatch('regions/fetchAll'),
			// this.$store.dispatch('plans/fetchAll')
			!this.plans.length && !Object.keys(this.activePlan).length
				? this.$store.dispatch('plans/fetchAll')
				: null,
			
			// materials
			useMaterialsPlan && !this.allMaterials.length
				? this.$store.dispatch('materials/fetchAll', {})
				: null,
		]).then(() => {
			isDateInActivePlanRange =
				moment(moment()).isBetween(this.activePlan.fromDate, this.activePlan.toDate, 'days', '[]');
			if (!this.plans.length || isDateInActivePlanRange) {
				this.$store.dispatch('plans/create', {
					employeeId: this.self.employeeId,
					fromDate: moment().add(1, 'months').startOf('months').format('YYYY-MM-DD'),
					toDate: moment().add(1, 'months').endOf('months').format('YYYY-MM-DD')
				}).then(() => {
					this.$store.dispatch('plans/fetchAll').finally(() => {
						this.loading = false;
					})
				})
			}
		}).finally(() => {
			if (this.plans.length > 0 || !isDateInActivePlanRange) this.loading = false;
		})
	}
};
</script>

<style lang="scss">
#plans {
	overflow: hidden;
	.v-slide-group {
		&__prev, &__next {
			min-width: 30px;
		}
	}
	.planned-visit {
		&__container {
			display: flex;
			margin-bottom: .5rem;
			align-items: center;
			position: relative;
		}
		&__card {
			z-index: 1;
			flex-grow: 1;
			transition: all .4s ease;
		}
		&__action-btn {
			z-index: 0;
		}
	}
}
</style>