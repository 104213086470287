import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		materials: [],
	},

	actions: {
		fetchAll({ commit }, { name, materialFormId }) {
			return window.axios.get('/materials', {
				params: {
					name: name,
					materialFormId: materialFormId
				}
			})
				.then((response) => {
					commit(types.STORE_MATERIALS, response.data);
					return response.data
				})
		},

		fetchById({ commit }, { id }) {
			return window.axios.get(`/materials/${id}`).then((response) => {
				return response.data;
			})
		},

		reset({ commit }) {
			commit(types.RESET_MATERIALS);
		}
	},

	mutations: {
		[types.STORE_MATERIALS](state, materials) {
			state.materials = materials;
		},

		[types.RESET_MATERIALS](state) {
			state.materials = [];
		},
	},

	getters: {
		getMaterialById: state => id => state.materials.find(c => c.id === id),
		getMaterialByName: state => name => state.materials.find(c => c.name === name)
	}
}