import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import visits from './modules/visits'
import plans from './modules/plans'
import plannedVisits from './modules/planned-visits'
import regions from './modules/regions'
import workloads from './modules/workloads'
import entities from './modules/entities'
import materials from './modules/materials'
import materialForms from './modules/materials-forms'
import materialsTransactions from './modules/materials-transactions'
import specialties from './modules/specialties'
import profile from './modules/profile'
import exams from './modules/exams'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		visits,
		plans,
		plannedVisits,
		regions,
		workloads,
		entities,
		materials,
		materialForms,
		materialsTransactions,
		specialties,
		profile,
		exams
	}
})