import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import axios from 'axios'

import * as types from './store/mutation-types';
import { API_URL } from '@/helpers/constants'
import eventBus from './helpers/event-bus'

Vue.config.productionTip = false
Vue.prototype.$eventBus = eventBus;
window.moment = moment;

window.axios = axios.create({
	baseURL: API_URL
});

window.axios.interceptors.response.use(
	response => response,
	error => {
		if (error.response.status === 401 && router.currentRoute.name !== 'login') {
			store.dispatch('auth/logout');
			router.replace({ name: 'login' })
		}
		if (router.currentRoute.name !== 'login') {
			eventBus.$emit('show-snackbar', error.response.data.message, 'error');
		}
		return Promise.reject(error)
	}
)

if ('auth' in localStorage) {
	try {
		const auth = JSON.parse(localStorage.getItem('auth'));
		const authHeader = `Bearer ${auth.token}`;

		window.axios.defaults.headers.common.Authorization = authHeader;
		store.dispatch('auth/getSelf')
			.then(() => {
				store.commit(`auth/${types.LOGIN}`, auth);
			})
			.catch(() => {
				localStorage.removeItem('auth');
				router.push({ name: 'login' });
			});
	} catch (e) {
		localStorage.removeItem('auth');
	}
} else {
	router.push({ name: 'login' });
}

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')