import moment from 'moment';
import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		plans: [],
		activePlan: {}
	},

	actions: {
		fetchAll({ commit }) {
			return window.axios.get('/visitsPlan/mine').then(({ data }) => {
				const activePlan = data.find(c => 
					moment(c.fromDate).format('YYYY-MM') === moment().add(1, 'months').format('YYYY-MM')
				) || {};
				// FIXME use next statements
				/* if (data.length === 1) {
					// activePlan = data[0];
					activePlan = data[data.length - 1];
				} else if (data.length > 1) {
					// activePlan = (data[1] && data[0]) || {};
					activePlan = (data[data.length - 2] && data[data.length - 1]) || {};
				} */
				commit(types.STORE_PLANS, data);
				commit(types.STORE_ACTIVE_PLAN, activePlan);
				return data;
			})
		},

		fetchByDate({ commit }, { fromDate, toDate }) {
			return window.axios.get('/visitsPlan', { params: { fromDate, toDate } }).then(({ data }) => data);
		},

		create({ commit }, { employeeId, fromDate, toDate }) {
			return window.axios.post('/visitsPlan', { employeeId, fromDate, toDate, active: true }).then(({ data }) => data);
		},

		/* delete({ commit }, { id }) {
			return window.axios.post(`/visitsPlan/${id}`);
		}, */
	},

	mutations: {
		[types.STORE_PLANS](state, plans) {
			state.plans = plans;
		},
		[types.STORE_ACTIVE_PLAN](state, activePlan) {
			state.activePlan = activePlan;
		},
	},

	getters: {
		getPlanById: state => id => state.plans.find(c => c.id === id)
	}
}