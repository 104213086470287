import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		transactions: []
	},

	actions: {
		fetchAll({ commit }) {
			return window.axios.get('/materialTransactions/selfTransactionsSummary')
				.then((response) => {
					commit(types.STORE_TRANSACTIONS_SUMMARY, response.data);
					return response.data;
				});
		},
        
		reset({ commit }) {
			commit(types.RESET_TRANSACTIONS_SUMMARY);
		}
	},

	mutations: {
		[types.STORE_TRANSACTIONS_SUMMARY](state, transactions) {
			state.transactions = transactions;
		},

		[types.RESET_TRANSACTIONS_SUMMARY](state) {
			state.transactions = [];
		},
	},

	getters: {
		getTransactionById: state => id => state.transactions.find(c => c.id === id),
	}
}