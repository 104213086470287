<template>
    <div ref="customers">
        <v-card flat color="lightgray">

            <!-- customer-dialog is declared here to use it as ref -->
            <customer-dialog ref="CustomerDialog"/>

            <!-- add customer -->
            <v-fab-transition>
                <v-btn
                    color="primary"
                    class="mb-15"
                    fab
                    fixed
                    bottom
                    right
                    v-show="isVisibleAdd"
                    @click="$refs.CustomerDialog.addCustomer()"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>

            <!-- filter -->
            <v-card-text class="d-flex flex-wrap justify-space-between align-center">
                <span class="text-body-1 me-6">Filter</span>
                <v-chip-group
                    v-model="selectedFilter"
                    show-arrows
                    center-active
                    color="secondary"
                    class="flex-grow-1"
                >
                    <v-chip active filter small outlined :value="1" class="flex-grow-1 justify-center">
                        Doctor
                    </v-chip>
                    <v-chip filter small outlined :value="2" class="flex-grow-1 justify-center">
                        Pharmacy
                    </v-chip>
                    <v-chip filter small outlined :value="0" class="flex-grow-1 justify-center">
                        Center
                    </v-chip>
                </v-chip-group>
            </v-card-text>

            <v-card-text class="pt-0">
                <div class="d-flex align-center">
					<v-text-field
						v-model="name"
						label="Customers name"
						color="primary"
						prepend-icon="mdi-home-city-outline"
						dense
						outlined
						clearable
						hide-details
						@keypress.enter="filter(); isFilteredByName = true;"
						@click:clear="resetFilterState"
					></v-text-field>
                    <!-- <v-btn v-if="false" @click="resetFilterState" text small color="grey darken-2">
                        <v-icon>{{isFilteredByName ? 'mdi-close' : 'mdi-magnify'}}</v-icon>
                    </v-btn> -->
                </div>
            </v-card-text>
        </v-card>

        <v-container>
            <v-row v-if="!loading && entities.length > 0">
                <v-col sm="5" cols="12" v-for="entity in entities" :key="entity.id" class="mx-sm-auto">
                    <customer-card
                      :customer="entity"
                      :disabled="disableCard"
                      class="mb-5"
                    />
                </v-col>
            </v-row>

            <div
              v-else-if="!loading && entities.length === 0"
              class="d-flex flex-column justify-center align-center"
              style="height: 45vh"
            >
                <v-icon x-large class="mb-3">mdi-flask-remove</v-icon>
                <span class="grey--text text--darken-2">NO CUSTOMERS TO SHOW</span>
            </div>

            <loader v-if="loading"/>
        </v-container>
    </div>
</template>

<script>
import CustomerCard from '../components/CustomerCard'
import CustomerDialog from '../components/CustomerDialog'
import Loader from '../components/Loader'
import { mapState } from 'vuex'
export default {
	data() {
		return {
			loading: true,
			disableCard: false,
			isVisibleAdd: false,

			isFilteredByName: false,
			selectedFilter: 1,
			name: null,

			perPage: 10,
			initPerpage: 10,
			nextPlusItemsforPerPage: 10,
		}
	},

	watch: {
		selectedFilter() {
			this.perPage = this.initPerpage;
			this.filter();
		}
	},

	methods: {
		filter() {
			// to load more item for the same page
			if (this.isFilteredByName) {
				this.perPage += this.nextPlusItemsforPerPage;
			} else {
				this.perPage = this.initPerpage;
			}

			// disabling customer card
			this.disableCard = true;

			// Active nav loading
			this.$emit('loading:active');
			this.$store.dispatch('entities/fetchAllData', {
				type: this.selectedFilter,
				name: this.name,
				perPage: this.perPage
			})
				.finally(() => {
					this.$emit('loading:disable');
					this.disableCard = false;
				})
		},

		resetFilterState() {
			if (this.isFilteredByName) {
				this.perPage = this.initPerpage;
				this.isFilteredByName = false;
				this.name = null;
				this.filter();
			}
		},

		loadMoreOnScroll() {
			// if srolled to bottom and number of entities not less then perpage load more items
			if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !(this.entities.length < this.perPage)) {
				if (this.isFilteredByName) {
					this.filter();
				} else {
					// to load more item for the same page
					this.perPage += this.nextPlusItemsforPerPage;

					// disabling customer card
					this.disableCard = true;

					// Active nav loading
					this.$emit('loading:active');
					this.$store.dispatch('entities/fetchAllData', {
						type: this.selectedFilter,
						name: null,
						perPage: this.perPage
					})
						.finally(() => {
							this.$emit('loading:disable');
							this.disableCard = false;
						})
				}
			}
		}
	},

	computed: {
		...mapState({
			entities: state => state.entities.entities,
			specialties: state => state.specialties.specialties
		}),
	},

	components: {
		CustomerCard,
		CustomerDialog,
		Loader
	},
    
	mounted() {
		Promise.all([
			this.$store.dispatch('entities/fetchAllData', {
				type: this.selectedFilter,
				name: null,
				perPage: this.perPage
			}),
			this.$store.dispatch('specialties/fetchAll'),
			this.$store.dispatch('regions/fetchAll'),
			this.$store.dispatch('workloads/fetchAll')
		])
			.finally(() => {
				setTimeout(() => {
					this.isVisibleAdd = true;
				}, 200);
				this.loading = false;
			});

		window.addEventListener('scroll', this.loadMoreOnScroll);
	},

	beforeDestroy() {
		this.$store.dispatch('specialties/reset');
		this.$store.dispatch('entities/reset');
		this.$store.dispatch('regions/reset');
		this.$store.dispatch('workloads/reset');

		window.removeEventListener('scroll', this.loadMoreOnScroll);
	}
}
</script>

<style>

</style>