import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		specialties: [],
	},

	actions: {
		fetchAll({ commit }) {
			return window.axios.get('/specialties').then((response) => {
				commit(types.STORE_SPECIALTIES, response.data);
				return response.data;
			})
		},

		reset({ commit }) {
			commit(types.RESET_SPECIALTIES);
		}
	},

	mutations: {
		[types.STORE_SPECIALTIES](state, specialties) {
			state.specialties = specialties;
		},

		[types.RESET_SPECIALTIES](state) {
			state.specialties = [];
		},
	},

	getters: {
		getSpecialtyById: state => id => state.specialties.find(c => c.id === id)
	}
}