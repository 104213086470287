<template>
    <div>
        <v-card flat color="lightgray">
            <v-card-text class="text-body-1">
                Stock Quantities
            </v-card-text>
        </v-card>

        <v-container>
            <div v-if="!loading && transactions.length > 0">
                <v-row class="mx-auto mb-0" style="width: 95%">
                    <v-col cols="9">
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-pill</v-icon>
                        <span class="text-capitalize primary--text text--darken-1">Sample Name</span>
                    </v-col>
                    <v-col cols="3" class="text-center">Remaining</v-col>
                </v-row>

                <sample-card
                  v-for="transaction in transactions"
                  :key="transaction.materialId"
                  :sample="transaction"
                  class="mb-2"
                  @on-swipe="fetchVisit()"
                />
            </div>

            <div
              v-else-if="!loading && transactions.length === 0"
              class="d-flex flex-column justify-center align-center"
              style="height: 65vh"
            >
                <v-icon x-large class="mb-3">mdi-pill</v-icon>
                <span class="grey--text text--darken-2">NO SAMPLES TO SHOW</span>
            </div>
        </v-container>

        <loader v-if="loading"/>
    </div>
</template>

<script>
import SampleCard from '../components/SampleCard'
import Loader from '../components/Loader'
import { mapState } from 'vuex'
export default {
	data() {
		return {
			loading: true
		}
	},

	components: {
		Loader,
		SampleCard,
	},

	mounted() {
		this.loading = true;
		Promise.all([
			this.$store.dispatch('materialsTransactions/fetchAll'),
			this.$store.dispatch('materialForms/fetchAll'),
			this.$store.dispatch('materials/fetchAll', { name: null, materialFormId: null })
		])
			.finally(() => {
				this.loading = false;
			})
	},

	computed: {
		...mapState({
			transactions: state => state.materialsTransactions.transactions
		})
	},

	beforeDestroy() {
		this.$store.dispatch('materialsTransactions/reset');
		this.$store.dispatch('materialForms/reset');
		this.$store.dispatch('materials/reset');
	}
}
</script>

<style>

</style>