<template>
    <v-card
		flat
		tile
		width="100%"
		class="red lighten-1 text-center"
	>
		<v-bottom-navigation
			app
			grow
			background-color="lightgray"
			height="65"
			color="primary"
		>
			<v-btn :to="{name: 'visits'}" exact min-width="0" class="pa-0">
				<span class="mt-1 text-body-2">Visits</span>
				<v-icon>mdi-medical-bag</v-icon>
			</v-btn>

			<v-btn :to="{name: 'plans'}" exact min-width="0" class="pa-0">
				<span class="mt-1 text-body-2">Plans</span>
				<v-icon>mdi-notebook-edit-outline</v-icon>
			</v-btn>

			<v-btn :to="{name: 'customers'}" exact min-width="0" class="pa-0">
				<span class="mt-1 text-body-2">Customers</span>
				<v-icon>mdi-home-city-outline</v-icon>
			</v-btn>

			<v-btn :to="{name: 'samples'}" exact min-width="0" class="pa-0">
				<span class="mt-1 text-body-2">Samples</span>
				<v-icon>mdi-pill</v-icon>
			</v-btn>

			<v-btn :to="{name: 'products'}" exact min-width="0" class="pa-0">
				<span class="mt-1 text-body-2">Products</span>
				<v-icon>mdi-palette-swatch</v-icon>
			</v-btn>
		</v-bottom-navigation>
	</v-card>

</template>

<script>
export default {
}
</script>

<style>
</style>