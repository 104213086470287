<template>
    <div>
        <!-- filter -->
        <v-card flat color="lightgray">
            <v-card-text class="d-flex flex-wrap justify-space-between align-center">
                <span class="text-body-1 me-6">Filter</span>
				<v-autocomplete
					v-model="materialFormId"
					label="Product form"
					:items="materialForms"
					item-text="name"
					item-value="id"
					color="primary"
					:disabled="loading"
					:loading="loading"
					flat
					dense
					clearable
					hide-details
					@change="filter"
				></v-autocomplete>
            </v-card-text>
            
            <v-card-text class="pt-2">
                <div class="d-flex align-center">
                    <v-text-field
                      v-model="name"
                      label="Product name"
                      color="primary"
                      prepend-icon="mdi-palette-swatch"
                      dense
                      outlined
                      clearable
                      hide-details
                      @keypress.enter="filter(); isFilteredByName = true;"
                      @click:clear="setFilterState"
                    ></v-text-field>
                </div>
            </v-card-text>
        </v-card>

        <v-container>
            <v-row v-if="!loading && materials.length > 0" dense>
				<v-col
					sm="5"
					cols="12"
					v-for="material in materials"
					:key="material.id"
					class="mb-4 mx-sm-auto"
				>
                    <product-card
                      :material="material"
                      :disabled="disableCard"
                    />
                </v-col>
            </v-row>

            <div
              v-else-if="!loading && materials.length === 0"
              class="d-flex flex-column justify-center align-center"
              style="height: 45vh"
            >
                <v-icon x-large class="mb-3">mdi-flask-remove</v-icon>
                <span class="secondary--text text--darken-2">NO MATERIALS TO SHOW</span>
            </div>

            <loader v-if="loading"/>
        </v-container>
    </div>
</template>

<script>
import ProductCard from '../components/ProductCard'
import Loader from '../components/Loader'
import { mapState } from 'vuex';
export default {
	data() {
		return {
			loading: true,
			disableCard: false,
			isFilteredByName: false,

			// data
			name: null,
			materialFormId: null,
		}
	},

	methods: {
		filter() {
			// disabling material card
			this.disableCard = true;
            
			// Active nav loading
			this.$emit('loading:active');
			this.$store.dispatch('materials/fetchAll', {
				name: this.name,
				materialFormId: this.materialFormId
			})
				.finally(() => {
					this.$emit('loading:disable');
					this.disableCard = false;
				})
		},

		setFilterState() {
			if (this.isFilteredByName) {
				this.isFilteredByName = false;
				this.name = null;
				this.filter();
			}
		}
	},

	components: {
		ProductCard,
		Loader
	},

	computed: {
		...mapState({
			materials: state => state.materials.materials,
			materialForms: state => state.materialForms.materialForms
		})
	},

	mounted() {
		Promise.all([
			this.$store.dispatch('materials/fetchAll', { name: null, materialFormId: null }),
			this.$store.dispatch('materialForms/fetchAll')
		])
			.finally(() => {
				this.loading = false;
			})
	},

	beforeDestroy() {
		this.$store.dispatch('materialForms/reset');
	}
}
</script>

<style>

</style>