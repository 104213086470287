import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		entities: []
	},

	actions: {
		fetchAll({ commit }, { type, name: query, regionId }) {
			/*
                type:
                0 = Center
                1 = Doctor
                2 = Pharmacy
                3 = External
            */
			return window.axios.get('/entities/all', {
				params: {
					type,
					query,
					regionId,
				}
			})
				.then((response) => {
					commit(types.STORE_ENTITIES, response.data);
					return response.data;
				})
		},

		fetchAllData({ commit }, { type, name, perPage }) {
			/*
                type = 0 => Center
                type = 1 => Doctor
                type = 2 => Pharmacy
                type = 3 => External
            */
			return window.axios.get('/entities', {
				params: {
					type,
					name,
					includeContactInfo: true,
					includeAddresses: true,
					nonDeleted: true,
					page: 1,
					perPage
				}
			})
				.then((response) => {
					commit(types.STORE_ENTITIES, response.data);
					return response.data;
				})
		},

		modify({ commit }, { requestType, entityId, entityType, name, reason, specialtyId, entityAddresses, contactInfos }) {
			/*
                requestType:
                0 = Creation
                1 = Modification
                2 = Deletion

                entityType:
                0 = Center
                1 = Doctor
                2 = Pharmacy
                3 = External
            */
			return window.axios.post('/requests/entitymodifications', { requestType, entityId, entityType, name, reason, specialtyId, entityAddresses, contactInfos })
		},

		reset({ commit }) {
			commit(types.RESET_ENTITIES);
		}
	},

	mutations: {
		[types.STORE_ENTITIES](state, entities) {
			state.entities = entities;
		},
        
		[types.RESET_ENTITIES](state) {
			state.entities = [];
		},
	},

	getters: {
		getEntityById: state => id => state.entities.find(c => c.id === id)
	}
}