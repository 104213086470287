import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		regions: []
	},

	actions: {
		fetchAll({ commit }) {
			return window.axios.get('/regions/mine')
				.then((response) => {
					commit(types.STORE_REGIONS, response.data);
					return response.data;
				})
		},

		reset({ commit }) {
			commit(types.RESET_REGIONS);
		}
	},

	mutations: {
		[types.STORE_REGIONS](state, regions) {
			state.regions = regions;
		},

		[types.RESET_REGIONS](state) {
			state.regions = [];
		}
	},

	getters: {
		getRegionById: state => id => state.regions.find(c => c.id === id)
	}
}