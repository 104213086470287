import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		workloads: []
	},

	actions: {
		fetchAll({ commit }) {
			return window.axios.get('/workload')
				.then((response) => {
					commit(types.STORE_WORKLOAD, response.data);
					return response.data;
				})
		},

		reset({ commit }) {
			commit(types.RESET_WORKLOAD);
		}
	},

	mutations: {
		[types.STORE_WORKLOAD](state, workloads) {
			state.workloads = workloads;
		},

		[types.RESET_WORKLOAD](state) {
			state.workloads = [];
		}
	},

	getters: {
		getWorkloadById: state => id => state.workloads.find(c => c.id === id)
	}
}