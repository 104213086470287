<template>
    <div>
        <v-card flat color="lightgray">
            <v-card-text class="text-h6 font-weight-bold">
                {{userData.name}}
            </v-card-text>
        </v-card>

        <!-- salary-sheet is declared here to use it as ref -->
        <salary-sheet ref="SalarySheet" :salary="salary"/>

        <!-- salary-sheet is declared here to use it as ref -->
        <vication-sheet ref="VicationSheet"/>

        <!-- administrative-sheet is declared here to use it as ref -->
        <administrative-sheet ref="AdministrativeSheet"/>

        <loader v-if="loading"/>

        <v-container v-else>
            <!-- buttons -->
            <v-row justify="center" class="mb-5">
                <!-- Administrative button -->
                <v-col cols="3" class="d-inline-flex flex-column align-center" >
					<v-btn
						fab
						color="primary"
						@click="$refs.AdministrativeSheet.activeSheet"
					>
                        <v-icon size="1.9rem">mdi-clipboard-text-outline</v-icon>
                    </v-btn>
                    <span class="mt-3 text-subtitle-2">Administrative</span>
                </v-col>

                <!-- Leaves button -->
                <v-col cols="4" class="d-inline-flex flex-column align-center">
					<v-btn
						fab
						color="primary"
						@click="$refs.VicationSheet.activeSheet"
					>
                        <v-icon size="1.9rem">mdi-airplane</v-icon>
                    </v-btn>
                    <span class="mt-3 text-subtitle-2">Leaves</span>
                </v-col>

                <!-- Salary botton -->
                <v-col cols="3" class="d-inline-flex flex-column align-center">
                    <v-btn
                      fab
                      @click="$refs.SalarySheet.activeSheet"
                      color="primary"
                    >
                        <v-icon size="1.9rem">mdi-currency-usd</v-icon>
                    </v-btn>
                    <span class="mt-3 text-subtitle-2">Salary</span>
                </v-col>
            </v-row>

            <!-- Visites -->
            <v-card outlined class="mb-5">
                <v-card-title class="text-body-2">
                    <v-icon color="secondary" class="me-2">mdi-briefcase-check-outline</v-icon>
                    <span>Visites</span>
                </v-card-title>

                <v-card-text>
                    <span class="text-subtitle-2 font-weight-bold">Today's Visites</span>
                    <v-progress-linear
                      color="primary"
                      class="mt-3 mb-1"
                      background-color="grey lighten-2"
                      rounded
                      height="10"
                      :value="(target.visitsToday / (target.value || 10)) * 100"
                    />
                    <span>
                        You have completed {{target.visitsToday || 0 }} out of
                        {{ target.value || 10 }}
                    </span>
                </v-card-text>
            </v-card>

            <v-row>
                <!-- Last Rating -->
                <v-col cols="6">
                    <v-card outlined>
                        <v-card-title class="text-body-2">
                            <v-icon color="secondary" class="me-2">mdi-finance</v-icon>
                            <span>Last Rating</span>
                        </v-card-title>

                        <v-card-text class="text-center">
                            <v-progress-circular
                              color="primary"
                              size="110"
                              width="8"
                              :value="review.averageMarks ? review.averageMarks.sum : 0"
                              rotate="-90"
                            >
                                <span class="text-h6 grey--text text--darken-2">
                                    {{(review.averageMarks ? review.averageMarks.sum : 0) + '%'}}
                                </span>
                            </v-progress-circular>
                            <p class="mt-2 mb-0">
                                Last Rating: {{review.reviews.length > 0 ? review.reviews[0].marks.sum : 0}}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- Leaves -->
                <v-col cols="6">
                    <v-card outlined>
                        <v-card-title class="text-body-2">
                            <v-icon color="secondary" class="me-2">mdi-airplane</v-icon>
                            <span>Leaves</span>
                        </v-card-title>

                        <v-card-text class="text-center">
                            <v-progress-circular
                              color="primary"
                              size="110"
                              width="8"
                              :value="(leaves.remainingNumberOfLeaves / leaves.daysCount) * 100"
                              rotate="-90"
                            >
                                <span class="text-h6 grey--text text--darken-2">
									{{leaves.remainingNumberOfLeaves}}/{{leaves.daysCount}}
								</span>
                            </v-progress-circular>

                            <p class="mt-2 mb-0">{{leaves.remainingNumberOfLeaves}} Leaves Remaining</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Loader from '../components/Loader'
import SalarySheet from '../components/SalarySheet'
import VicationSheet from '../components/VicationSheet'
import AdministrativeSheet from '../components/AdministrativeSheet'
import { mapState } from 'vuex'
export default {
	data() {
		return {
			loading: true,

			snackbar: false,
			message: null,
		}
	},

	computed: {
		...mapState({
			userData: state => state.auth.userData,
			self: state => state.auth.self,
			target: state => state.profile.target,
			salary: state => state.profile.salary,
			leaves: state => state.profile.leaves,
			review: state => state.profile.review,
		})
	},

	components: {
		Loader,
		SalarySheet,
		VicationSheet,
		AdministrativeSheet
	},

	mounted() {
		Promise.all([
			this.$store.dispatch('profile/target'),
			this.$store.dispatch('profile/salary'),
			this.$store.dispatch('profile/leaves', { employeeId: this.self.employeeId }),
			this.$store.dispatch('profile/review')
		]).finally(() => {
			this.loading = false;
		})
	},

	beforeDestroy() {
		this.$store.dispatch('profile/reset');
	}
}
</script>

<style>

</style>