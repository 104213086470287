<template>
    <div>
        <div>
            <p class="direction text-body-1">
                <span class="direction-name">{{ visit.entity.name }}</span>
                <v-spacer/>
                <span v-if="visit.entitySpecialty" class="success--text">{{ visit.entitySpecialty.name }}</span>
            </p>
        </div>

        <div class="d-flex align-center">
            <!-- deleteFab -->
            <v-fab-transition>
                <v-btn fab absolute left v-show="deleteFab" dark small text color="red darken-2">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-fab-transition>

            <!-- card -->
            <v-card
              outlined
              width="90%"
              class="visit-card mx-auto"
              v-ripple="{class:'visit-ripple'}"
              v-touch.sync="{
                move: (e) => { touchMove(e) },
                end: (e) => { endOffset(e) }
              }"
              :style="'transform: translateX('+ touchSwipeOffset +'px)'"
              :disabled="disabled"
            >
                <v-card-text @click="$emit('on-click')" style="position: relative">
                    <!-- person name -->
                    <div v-if="visit.residentDoctorNames">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-account</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">person name</span>
                        </div>
                        <div>{{ visit.residentDoctorNames }}</div>
                    </div>

                    <!-- samples -->
                    <div>
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-pill</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">samples</span>
                        </div>
                        <div v-if="visit.materialAmounts.length > 0">{{ samples }}</div>
                        <div v-else>No samples </div>
                    </div>

                    <!-- Unavailable Products -->
                    <div v-if="visit.unavailableMaterials.length > 0" class="mt-1">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-close</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">Unavailable</span>
                        </div>
                        <div>{{ unavailableMaterials }}</div>
                    </div>

                    <!-- Competitors -->
                    <div v-if="visit.competitiveSamples.length > 0" class="mt-1">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-trending-up</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">Competitors</span>
                        </div>
                        <div>{{ visit.competitiveSamples.join(', ') }}</div>
                    </div>

                    <!-- Core message -->
                    <div v-if="visit.coreMessage" class="mt-1">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-note-outline</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">Core message</span>
                        </div>
                        <div>{{ visit.coreMessage }}</div>
                    </div>

                    <!-- durationInMinutes -->
                    <div v-if="visit.durationInMinutes" class="mt-1">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-clock-outline</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">Duration</span>
                        </div>
                        <div>{{ visit.durationInMinutes }} min</div>
                    </div>

                    <!-- other materials table -->
                    <div v-if="visit.otherSamples" class="mt-1">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-plus-minus-box</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">other materials</span>
                        </div>
                        <div>{{ visit.otherSamples }}</div>
                    </div>

                    <!-- notes -->
                    <div v-if="visit.notes" class="mt-1">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-note-multiple-outline</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">notes</span>
                        </div>
                        <div>{{ visit.notes }}</div>
                    </div>

                    <!-- Objections -->
                    <div v-if="visit.objections" class="mt-1">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-alert-circle-outline</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">Objections</span>
                        </div>
                        <div>{{ visit.objections }}</div>
                    </div>

                    <!-- next call -->
                    <div v-if="visit.purpose" class="mt-1">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-check</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">Next Call</span>
                        </div>
                        <div>{{ visit.purpose }}</div>
                    </div>

                    <!-- center notes -->
                    <div v-if="visit.nextVisitNotes" class="mt-1">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-note-multiple-outline</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">Center Notes</span>
                        </div>
                        <div>{{ visit.nextVisitNotes }}</div>
                    </div>  
                </v-card-text>
                
                <v-card-actions class="pt-0" v-if="!isTouchDevice">
                    <v-spacer/>
                    <v-btn
                        text
                        color="red lighten-1"
                        @click="$emit('on-swipe-right')"
                    >
                        delete
                    </v-btn>
                    <v-btn
                        text
                        color="success"
                        @click="editVisit"
                    >
                        edit
                    </v-btn>
                </v-card-actions>
            </v-card>

            <!-- EditFab -->
            <v-fab-transition>
                <v-btn fab absolute right v-show="EditFab" dark small text color="success darken-2">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </v-fab-transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	props: [
		'disabled',
		'visit'
	],

	data() {
		return {
			touchSwipeOffset: 0,
			maxOffsetAllowed: 30,
			isTouchDevice: true,

			snackbar: false,
			message: null,

			deleteFab: false,
			EditFab: false,
		}
	},

	methods: {
		// touch methods
		endOffset(e) {
			setTimeout(() => {
				if (this.touchSwipeOffset === this.maxOffsetAllowed) {
					this.$emit('on-swipe-right');
				} else if (this.touchSwipeOffset === -this.maxOffsetAllowed) {
					this.editVisit();
				}
				this.deleteFab = false;
				this.EditFab = false;
				this.touchSwipeOffset = 0;
			}, 200);
		},

		touchMove(e) {
			const offset = e.touchmoveX - e.touchstartX;
			this.touchSwipeOffset = offset > -this.maxOffsetAllowed && offset < this.maxOffsetAllowed ? offset : Math.sign(offset) * this.maxOffsetAllowed;

			if (this.touchSwipeOffset === this.maxOffsetAllowed) {
				this.deleteFab = true;
				this.EditFab = false;
			} else if (this.touchSwipeOffset === -this.maxOffsetAllowed) {
				this.deleteFab = false;
				this.EditFab = true;
			} else {
				this.deleteFab = false;
				this.EditFab = false;
			}
		},

		// component methods
		getFullMaterialName(material) {
			const details = [
				this.getMaterialFormById(material.materialFormId).name,
				material.dose,
				material.strength
			];

			return `${material.name} (${details.filter(c => c != null).join(' ')})`;
		},

		editVisit() {
			const visitDate = window.moment(this.visit.createdAt);
			const today = window.moment();

			if (today.diff(visitDate, 'days') <= 1) {
				this.$parent.$refs.VisitDialog.editVisit(this.visit.id);
			} else {
				this.$emit('on-edit-prevent', 'You can ONLY EDIT today visits')
			}
		},
	},

	computed: {
		samples() {
			const samples = [];
			this.visit.materialAmounts.forEach(materialAmount => {
				const material = this.getMaterialById(materialAmount.materialId);
				const amount = materialAmount.amount;
				samples.push(this.getFullMaterialName(material) + ' (×' + amount + ')');
			});
			return samples.join(', ');
		},

		unavailableMaterials() {
			const materials = [];
			this.visit.unavailableMaterials.forEach(materialName => {
				const material = this.getMaterialByName(materialName);
				materials.push(this.getFullMaterialName(material));
			});
			return materials.join(', ');
		},

		...mapGetters({
			getMaterialById: 'materials/getMaterialById',
			getMaterialByName: 'materials/getMaterialByName',
			getMaterialFormById: 'materialForms/getMaterialFormById'
		})
	},

	mounted() {
		this.isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
	}
}
</script>

<style lang="scss">
@import '../scss/vars.scss';
.visit-card{
    transition: transform .4s ease;
    overflow: hidden;
}
.visit-ripple{
    color: rgba($primary, .5);
}
.direction{
    display: flex;
    margin-bottom: .5rem;
    align-items: center;
    position: relative;
    
    &-name{
        display: flex;
        align-items: center;
        margin-left: 22px;

        &::before{
            content: '';
            display: inline-block;
            position: absolute;
            left: -18px;
            width: 30px;
            height: 8px;
            border-radius: 10px;

            background-color: $success;
        }

    }
}
</style>