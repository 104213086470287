export const GET_USER_DATA = 'GET_USER_DATA';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const STORE_VISITS = 'STORE_VISITS';
export const RESET_VISITS = 'RESET_VISITS';

export const STORE_PLANNED_VISITS = 'STORE_PLANNED_VISITS';
export const CLEAR_PLANNED_VISITS = 'CLEAR_PLANNED_VISITS';

export const STORE_ACTIVE_PLAN = 'STORE_ACTIVE_PLAN';
export const STORE_PLANS = 'STORE_PLANS';

export const STORE_WORKLOAD = 'STORE_WORKLOAD';
export const RESET_WORKLOAD = 'RESET_WORKLOAD';

export const STORE_REGIONS = 'STORE_REGIONS';
export const RESET_REGIONS = 'RESET_REGIONS';

export const STORE_ENTITIES = 'STORE_ENTITIES';
export const RESET_ENTITIES = 'RESET_ENTITIES';

export const STORE_MATERIALS = 'STORE_MATERIALS';
export const STORE_MATERIAL_FORMS = 'STORE_MATERIAL_FORMS';
export const RESET_MATERIALS = 'RESET_MATERIALS';
export const RESET_MATERIAL_FORMS = 'RESET_MATERIAL_FORMS';

export const STORE_TRANSACTIONS_SUMMARY = 'STORE_TRANSACTIONS_SUMMARY';
export const RESET_TRANSACTIONS_SUMMARY = 'RESET_TRANSACTIONS_SUMMARY';

export const STORE_SPECIALTIES = 'STORE_SPECIALTIES';
export const RESET_SPECIALTIES = 'RESET_SPECIALTIES';

export const STORE_TARGET = 'STORE_TARGET';
export const STORE_SALARY = 'STORE_SALARY';
export const STORE_LEAVES = 'STORE_LEAVES';
export const STORE_REVIEW = 'STORE_REVIEW';
export const RESET_PROFILE = 'RESET_PROFILE';

export const STORE_EXAM = 'STORE_EXAM';
export const RESET_EXAM = 'RESET_EXAM';