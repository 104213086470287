<template>
        <div class="delete-dialog">
            <v-dialog
                v-model="dialog"
                transition="slide-x-transition"
                max-width="700"
            >
                <v-card flat>
                    <v-toolbar
                        color="red lighten-1"
                        dark
                        flat
                        dense
                        class="text-uppercase justify-center"
                    >
                        <v-spacer/>
                        Confirm delete
                        <v-spacer/>
                    </v-toolbar>
                    <v-card-text class="px-4 py-2">
                        <!-- dialog content -->
                        <!-- default slot -->
                        <slot></slot>
                    </v-card-text>
                    <v-card-actions  class="justify-end">
                        <v-btn
                            @click="deleteData"
                            :loading="loading"
                            color="red lighten-1 white--text"
                        >
                            Delete
                        </v-btn>
                        <v-btn
                            @click="dialog = false"
                            :disabled="loading"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-snackbar
              v-model="snackbar"
              transition="slide-y-reverse-transition"
              color="red lighten-1 white--text"
              class="text-body-2"
            >
                {{ message }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			snackbar: false,
			message: null,
            
			dataId: null,
			moduleType: null,
			additionalData: {},

			loading: false,
		}
	},

	methods: {
		activeDialog(id, moduleType) {
			this.dialog = true;
			this.dataId = id;
			this.moduleType = moduleType;
		},

		setAdditionalData(additionalData) {
			this.additionalData = additionalData;
		},

		deleteData() {
			this.loading = true;

			this.$store.dispatch(`${this.moduleType}/delete`, { id: this.dataId, ...this.additionalData })
				.then(() => {
					this.message = 'Delete operation completed successfully.';
					this.$emit('on-delete');
					this.dialog = false;
				})
				.catch((e) => {
					this.message = e.response.data.message;
				})
				.finally(() => {
					// this.snackbar = true;
					this.loading = false;
				});

			this.dataId = null;
			this.moduleType = null;
		},
	}
}
</script>

<style lang="scss">
.delete-dialog{
    overflow: hidden;
}
</style>