var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"visits-frequency"}},[_c('v-card',{attrs:{"flat":"","color":"lightgray"}},[_c('v-card-text',{staticClass:"d-flex justify-space-between align-center py-2"},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.selectedMonth,"persistent":"","max-width":"300px"},on:{"update:returnValue":function($event){_vm.selectedMonth=$event},"update:return-value":function($event){_vm.selectedMonth=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"d-flex justify-space-between align-center primary--text"},'div',attrs,false),on),[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"me-2",attrs:{"color":"primary"}},[_vm._v("mdi-calendar")]),_c('span',{staticClass:"text-h5 me-2"},[_vm._v(_vm._s(_vm.moment(_vm.selectedMonth, 'YYYY-MM').format('MMMM')))]),_c('span',{staticClass:"text-subtitle-2 align-self-end"},[_vm._v(_vm._s(_vm.moment(_vm.selectedMonth, 'YYYY-MM').format('YYYY')))])],1)])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-date-picker',{attrs:{"full-width":"","picker-date":"","type":"month","max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.selectedMonth !== _vm.tableMonth
								? (() => {
									_vm.$refs.dialog.save(_vm.selectedMonth);
									_vm.selectedMonth = _vm.moment(_vm.selectedMonth, 'YYYY-MM').format('YYYY-MM');
									_vm.filter(Boolean(_vm.entityName), Boolean(_vm.regionId));
								})()
								: _vm.dialog = false;}}},[_vm._v(" OK ")])],1)],1),(false)?_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){_vm.selectedMonth !== _vm.tableMonth
						? (() => {
							_vm.selectedMonth = _vm.moment().format('YYYY-MM');
							_vm.filter(_vm.entities.length === 0, true);
						})()
						: null}}},[_vm._v(" current month ")]):_vm._e()],1)],1),_c('v-row',{staticClass:"ma-0 px-4",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Entity name","dense":"","hide-details":"","outlined":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.filter(true, false)},"change":function($event){$event ? _vm.filter(true, false) : null}},model:{value:(_vm.entityName),callback:function ($$v) {_vm.entityName=$$v},expression:"entityName"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"label":"Region","items":_vm.regions,"item-text":"name","item-value":"id","dense":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.filter(true, false)}},model:{value:(_vm.regionId),callback:function ($$v) {_vm.regionId=$$v},expression:"regionId"}})],1)],1),_c('v-container',[_c('v-data-table',{ref:"table",staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.loading ? [] : _vm.tableItems,"items-per-page":-1,"height":_vm.$vuetify.breakpoint.height - 235,"loading":_vm.loading,"fixed-header":"","hide-default-footer":"","mobile-breakpoint":"0","disable-sort":"","dense":""},scopedSlots:_vm._u([_vm._l((_vm.moment(_vm.tableMonth, 'YYYY-MM').daysInMonth()),function(i){return {key:`item.day${i}`,fn:function({ item, value, header }){return [_c('div',{key:i,staticClass:"state-cell",class:[
						{
							'success': value === _vm.visitStates.done,
							'red': value === _vm.visitStates.missed,
							'orange': value === _vm.visitStates.outOfPlan,
						}
					]},[_vm._v(" "+_vm._s(item.daysCount ? item.daysCount[header.value] : null)+" ")])]}}})],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }