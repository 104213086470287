import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		plannedVisits: []
	},

	actions: {
		fetchAll({ commit }, { fromDate }) {
			return window.axios.get('/plannedVisits/mine', { params: { fromDate } }).then(({ data }) => {
				commit(types.STORE_PLANNED_VISITS, data);
				return data;
			})
		},

		fetchByDate({ commit }, { date }) {
			return window.axios.get(`/plannedVisits/${date}/byDate`).then(({ data }) => data);
		},

		fetchById({ commit }, { id }) {
			return window.axios.get(`/plannedVisits/${id}`).then(({ data }) => data);
		},

		create({ commit }, { visitPlanId, entityId, materialsAmount, date }) {
			return window.axios.post('/plannedVisits', { visitPlanId, entityId, materialsAmount, date });
		},

		update({ commit }, { id, visitPlanId, entityId, materialsAmount, date }) {
			return window.axios.put(`/plannedVisits/${id}`, { visitPlanId, entityId, materialsAmount, date });
		},

		delete({ commit }, { id, visitPlanId }) {
			return window.axios.delete(`/plannedVisits/${id}`);
		},

		clear({ commit }) {
			commit(types.CLEAR_PLANNED_VISITS)
		}
	},

	mutations: {
		[types.STORE_PLANNED_VISITS](state, plannedVisits) {
			state.plannedVisits = plannedVisits;
		},

		[types.CLEAR_PLANNED_VISITS](state) {
			state.plannedVisits = [];
		},
	},

	getters: {
		getPlannedVisitById: state => id => state.plannedVisits.find(c => c.id === id)
	}
}