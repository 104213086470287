<template>
	<v-app-bar
		app
		color="primary"
		dark
		height="50"
		elevation="1"
	>
        <v-app-bar-nav-icon @click="$emit('drawer')" class="me-2"></v-app-bar-nav-icon>
        <h1 class="text-uppercase text-body-1">{{$route.name.replace(/-/g, ' ')}}</h1>
		
		<!-- loading line -->
		<v-progress-linear
			indeterminate
			absolute
			bottom
			:active="loading"
			height="4"
			background-color="primary"
			color="secondary lighten-1"
		></v-progress-linear>
    </v-app-bar>
</template>

<script>
export default {
	props: [
		'loading'
	]
}
</script>

<style>

</style>