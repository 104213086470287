import * as types from '../mutation-types';

export default {
	namespaced: true,
	state: {
		authenticated: false,
		userData: {
			name: null,
			employeeType: null
		},
		self: {
			username: null,
			employeeId: null,
		},
	},

	actions: {
		login({ commit, dispatch }, { username, password }) {
			return window.axios.post('/users/authenticate', { username, password })
				.then((response) => {
				/* 
				the following (if statement) is to check if how is logging in is medical rep
				and if medical rep log in will throw exciption with message and (name=42)
				*/
					if (response.data.userType !== 1) {
						const e = new Error('It is ALLOWED ONLY to medical reps to enter the app');
						e.name = 42;
						throw e;
					}

					const auth = {
						username, employeeId: response.data.employeeId, token: response.data.token, authenticated: true
					};
	
					localStorage.setItem('auth', JSON.stringify(auth));
					window.axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.token;
					return dispatch('getSelf').then(() => {
						commit(types.LOGIN, auth);
						return response.data;
					})
				// dispatch('users/getself', null, { root: true }).then(() => {
				// 	commit(types.LOGIN, auth);
				// });
				})
		},
	
		logout({ commit }) {
			localStorage.removeItem('auth');
			delete window.axios.defaults.headers.common.Authorization;
			commit(types.LOGOUT);
		},

		getSelf({ commit }) {
			return	window.axios.get('/users/getself').then((response) => {
				commit(types.GET_USER_DATA, response.data)
				return response.data;
			})
		},
	},

	mutations: {
		[types.GET_USER_DATA](state, userData) {
			state.userData = userData;
		},

		[types.LOGIN](state, payload) {
			state.authenticated = true;
			state.self.username = payload.username;
			state.self.employeeId = payload.employeeId;
		},
	
		[types.LOGOUT](state) {
			state.authenticated = false;
			state.self.username = null;
			state.self.employeeId = null;
		},
	},

	getters: {
		isAdmin: state => state.userData.employeeType === 2,
		getUserType: state => {
			if (state.userData.employeeType === 0) return 'supervisor'
			else if (state.userData.employeeType === 1) return 'medical rep'
			else if (state.userData.employeeType === 2) return 'admin'
		}
	},

	modules: {
	},
}