<template>
    <v-bottom-sheet v-model="sheet" persistent content-class="rounded-t-lg">
		<!-- card -->
		<v-card class="rounded-t-lg">
			<!-- title -->
			<v-toolbar flat class="text-h6 text-uppercase">
				<v-spacer/>
				Add New Vacation Request
				<v-spacer/>
			</v-toolbar>
			<v-form @submit.prevent="submit" ref="form">
				<v-card-text class="pt-1">
					<v-row dense>
						<!-- type -->
						<v-col md="3" sm="6" cols="12">
							<v-select
								v-model.number="type"
								:items="types"
								item-text="name"
								item-value="id"
								label="Type"
								flat
								dense
								:rules="rules.requiredAsNumber"
								:disabled="loading"
							></v-select>
						</v-col>

						<!-- days -->
						<v-col md="3" sm="6" cols="12">
							<v-text-field
								v-model.number="days"
								label="Days Count"
								type="number"
								min='1'
								flat
								dense
								hide-spin-buttons
								:rules="rules.number"
								:disabled="loading"
							></v-text-field>
						</v-col>

						<!-- start date -->
						<v-col md="3" sm="6" cols="12">
							<v-menu
								ref="menu"
								v-model="menu"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								min-width="auto"
								:disabled="loading"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="date"
										label="Start Date"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
										dense
										:disabled="loading"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="date"
									:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000 * 170)).toISOString().substr(0, 10)"
									min="2005-01-01"
									@change="save"
								></v-date-picker>
							</v-menu>
						</v-col>
						
						<!-- reason -->
						<v-col md="3" sm="6" cols="12">
								<v-text-field
								v-model="reason"
								label="Reason"
								flat
								dense
								:rules="rules.required"
								:disabled="loading"
								></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				
				<v-card-actions class="pt-0">
					<v-spacer/>
					<v-btn
						@click="sheet = false"
						rounded
						text
						class="font-weight-bold px-8"
						:disabled="loading"
					>
						cancle
					</v-btn>
					<v-spacer/>
					<v-btn
						type="submit"
						class="px-11"
						color="primary"
						rounded
						:loading="loading"
						:disabled="loading"
					>
						Add
					</v-btn>
					<v-spacer/>
				</v-card-actions>
			</v-form>
		</v-card>
    </v-bottom-sheet>
</template>

<script>
import rules from '@/helpers/validation rules'
export default {
	data() {
		return {
			sheet: false,
			loading: false,

			rules,

			types: [
				{ id: 2, name: 'Sick Leave' },
				{ id: 3, name: 'Unpaid Leave' },
				{ id: 4, name: 'Paid Leave' },
			],
			menu: false,

			// data
			type: null,
			days: null,
			date: null,
			reason: null
		}
	},

	methods: {
		save (date) {
			this.$refs.menu.save(date)
		},

		reset() {
			if (this.type) {
				this.$refs.form.resetValidation();
			}

			this.type = null;
			this.days = null;
			this.date = null;
			this.reason = null;
		},

		activeSheet() {
			this.reset();
			this.sheet = true;
		},

		submit() {
			if (this.$refs.form.validate()) {
				this.loading = true;

				this.$store.dispatch('profile/creatDayOff', {
					date: this.date,
					reason: this.reason,
					type: this.type,
					days: this.days 
				})
					.then(() => {
						this.$eventBus.$emit('show-snackbar', 'The Request is sent');
						this.sheet = false;
					})
					.finally(() => {
						this.loading = false;
					})
			}
		}
	}
}
</script>

<style>

</style>