import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		materialForms: []
	},

	actions: {
		fetchAll({ commit }) {
			return window.axios.get('/materialforms').then((response) => {
				commit(types.STORE_MATERIAL_FORMS, response.data);
				return response.data;
			})
		},

		fetchById({ commit }, id) {
			return window.axios.get(`/materialforms/${id}`).then((response) => {
				return response.data;
			});
		},

		reset({ commit }) {
			commit(types.RESET_MATERIAL_FORMS);
		}
	},

	mutations: {
		[types.STORE_MATERIAL_FORMS](state, materialForms) {
			state.materialForms = materialForms;
		},

		[types.RESET_MATERIAL_FORMS](state) {
			state.materialForms = [];
		},
	},

	getters: {
		getMaterialFormById: state => id => state.materialForms.find(c => c.id === id)
	}
}