// this is validations rules for vuetify component validation
export default {
	required: [val => (val || '').length > 0 || 'This field is required'],
	name: [val => (val || '').length > 0 || 'This field is required'],
	number: [val => (val >= 0 && val !== null) || 'This number should be non-negative.'],
	requiredAsNumber: [val => (val >= 0 && val !== null) || 'This field is required'],
	url: [
		val => {
			const regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim;
			return regexp.test(val) || 'Invalid URL.'
		}
	],
	date: [
		val => {
			const date = /^(19|20)[0-9]{2}[-](1[0-2]|0[1-9])[-](0[1-9]|(1|2)[0-9]|3(0|1))$/;
			return date.test(val) || 'Invalid date. Date format is \'yyyy-mm-dd\'.'
		}
	],
	password: [val => (val || '').length >= 4 || 'password should be 4 characters at least'],
	email: [
		val => {
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return pattern.test(val) || 'Invalid e-mail.'
		}
	],
}