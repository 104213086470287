<template>
    <div>
        <v-card flat color="lightgray">
            <v-card-text class="text-body-1">
                Exams
            </v-card-text>
        </v-card>

        <v-card flat class="exam-card">
            <v-card-text>
                <Loader v-if="loading" height="73vh"/>
                <!-- exam link -->
                <div v-else class="d-flex flex-column justify-center align-center">
                    <v-icon size="80" class="mb-4">{{icon}}</v-icon>
                    <p class="text-h6 exam-text">{{examState()}}</p>
                    <v-btn
                      v-if="link"
                      :href="link"
                      target="_blnck"
                      class="font-weight-black"
                      color="success"
                    >
                        Go to exam
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import Loader from '../components/Loader'
import { mapState } from 'vuex'
export default {
	data() {
		return {
			// helpers
			id: null,

			loading: true,
			icon: null,
			link: null
		}
	},

	components: {
		Loader
	},

	computed: {
		...mapState({
			exam: state => state.exams.exam
		})
	},

	methods: {
		examState() {
			const examDate = window.moment(this.exam.date).format('YYYY-MM-DD');
			const todayDate = window.moment().format('YYYY-MM-DD');

			if (!this.exam.link) {
				this.icon = 'mdi-clipboard-text-off-outline';
				return 'No exams. Stay tuned!';
			} else if (examDate === todayDate) {
				this.icon = 'mdi-file-document-edit-outline';
				this.link = this.exam.link;
				return 'Exam is today!'
			} else {
				this.icon = 'mdi-clipboard-text-clock-outline';
				return 'Next exam is due in\n' + examDate;
			}
		},

		activeDialog() {
			this.dialog = true;
		}
	},

	mounted() {
		this.$store.dispatch('exams/fetchAll')
			.finally(() => {
				this.loading = false;
			})
	},

	beforeDestroy() {
		this.$store.dispatch('exams/reset');
	},
}
</script>

<style lang="scss" scoped>
.exam-card{
    height: 73vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.exam-text{
    text-align: center;
    white-space: pre-wrap;
}
</style>