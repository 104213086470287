<template>
    <div>
        <v-dialog
          v-model="dialog"
          persistent
          transition="slide-x-reverse-transition"
        >
            <v-card flat>
                <!-- title -->
                <v-toolbar
                  dark
                  flat
                  :color="isDeletionRequest ? 'red lighten-1' : 'primary'"
                  class="text-uppercase"
                >
                    <v-spacer/>
                    {{title}}
                    <v-spacer/>
                    <v-toolbar-items text samll>
                        <v-btn small text @click="dialog=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-form @submit.prevent="submit" ref="form">
                    <!-- radio buttons -->
                    <v-radio-group
                      row
                      hide-details
                      v-model="dirType"
                      v-if="id === null"
                      @change="reset"
                    >
                        <v-radio label="Doctors" :value="1" class="mx-auto" color="success"/>
                        <v-radio label="Pharmacies" :value="2" class="mx-auto" color="success"/>
                        <v-radio label="Centers" :value="0" class="mx-auto" color="success"/>
                    </v-radio-group>

                    <!-- card content -->
                    <v-card-text class="pb-0">
                        <v-container>
                            <v-row>
                                <!-- name -->
                                <v-col md="6" cols="12">
                                    <v-text-field
                                      v-model="name"
                                      label="Name"
                                      prepend-icon="mdi-account"
                                      dense
                                      :disabled="loading || isDeletionRequest"
                                      :rules="rules.required"
                                      required
                                    ></v-text-field>
                                </v-col>

                                <!-- specialty -->
                                <v-col md="6" cols="12" v-if="dirType === 1">
                                    <v-autocomplete
                                      v-model="specialtyId"
                                      :items="specialties"
                                      item-text="name"
                                      item-value="id"
                                      label="specialty"
                                      prepend-icon="mdi-account"
                                      dense
                                      :disabled="loading || isDeletionRequest"
                                      :rules="rules.required"
                                      required
                                    ></v-autocomplete>
                                </v-col>

                                <!-- addresses -->
                                <v-col cols="12">
                                    <div class="d-flex flex-grow-1 justify-space-between align-center">
                                        <div class="d-flex align-center">
                                            <span class="text-body-1">Addresses</span>
                                        </div>

                                        <v-btn text small @click="addAddress" v-if="!isDeletionRequest">
                                            <v-icon color="grey darken-3">mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </div>
                                    
                                    <!-- address card -->
                                    <v-row
                                      v-for="address in addresses"
                                      :key="address.id"
                                      class="align-center"
                                    >
                                        <v-col cols>
                                            <!-- address card -->
                                            <v-card class="mt-4">
                                                <v-card-text>    
                                                    <v-row>
                                                        <!-- region -->
                                                        <v-col cols="6">
                                                            <v-autocomplete
                                                              v-model="address.data.regionId"
                                                              :items="regions"
                                                              item-text="name"
                                                              item-value="id"
                                                              label="Region"
                                                              dense
                                                              :disabled="loading || isDeletionRequest"
                                                              :rules="rules.required"
                                                              required
                                                            ></v-autocomplete>
                                                        </v-col>

                                                        <!-- workload -->
                                                        <v-col cols="6">
                                                            <v-autocomplete
                                                              v-model="address.data.workload"
                                                              :items="workloads"
                                                              item-text="name"
                                                              item-value="id"
                                                              label="Workload"
                                                              dense
                                                              :disabled="loading || isDeletionRequest"
                                                              :rules="rules.required"
                                                              required
                                                            ></v-autocomplete>
                                                        </v-col>

                                                        <!-- address -->
                                                        <v-col cols="12">
                                                            <v-text-field
                                                              v-model="address.data.address"
                                                              label="Address"
                                                              dense
                                                              :disabled="loading || isDeletionRequest"
                                                              :rules="rules.required"
                                                              required
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>

                                        <!-- remove button -->
                                        <v-col cols="2" class="pa-0" v-if="!isDeletionRequest">
                                            <v-btn text small @click="removeAddress(address.id)">
                                                <v-icon color="grey darken-3">mdi-minus-circle-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <!-- contactInfos -->
                                <v-col cols="12">
                                    <div class="d-flex flex-grow-1 justify-space-between align-center">
                                        <div class="d-flex align-center">
                                            <span class="text-body-1">Contact Information</span>
                                        </div>

                                        <v-btn text small @click="addContact" v-if="!isDeletionRequest">
                                            <v-icon color="grey darken-3">mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </div>
                                    
                                    <!-- contactInfos card -->
                                    <v-row
                                      v-for="contactInfo in contactInfos"
                                      :key="contactInfo.id"
                                      class="align-center"
                                    >
                                        <v-col cols>
                                            <!-- contactInfos card -->
                                            <v-card class="mt-4">
                                                <v-card-text>    
                                                    <v-row>
                                                        <!-- Contact Type -->
                                                        <v-col cols="6">
                                                            <v-autocomplete
                                                              v-model.number="contactInfo.data.contactInfoType"
                                                              :items="contactInfoTypes"
                                                              item-text="name"
                                                              item-value="id"
                                                              label="Contact Type"
                                                              dense
                                                              :disabled="loading || isDeletionRequest"
                                                              :rules="rules.requiredAsNumber"
                                                              required
                                                            ></v-autocomplete>
                                                        </v-col>

                                                        <!-- contact Label -->
                                                        <v-col cols="6">
                                                            <v-text-field
                                                              v-model="contactInfo.data.value"
                                                              :label="contactInfo.data.contactInfoType !== null ? getContactLabel(contactInfo.data.contactInfoType) : 'contact'"
                                                              dense
                                                              :disabled="loading || isDeletionRequest"
                                                              :rules="rules.required"
                                                              required
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>

                                        <!-- remove button -->
                                        <v-col cols="2" class="pa-0" v-if="!isDeletionRequest">
                                            <v-btn text small @click="removeContact(contactInfo.id)">
                                                <v-icon color="grey darken-3">mdi-minus-circle-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <!-- reason -->
                                <v-col md="6" cols="12">
                                    <v-text-field
                                      v-model="reason"
                                      label="Reason"
                                      dense
                                      :disabled="loading"
                                      :rules="rules.required"
                                      required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-spacer/>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                          :color="isDeletionRequest ? 'red lighten-1' : 'success'"
                          text
                          type="submit"
                          :loading="loading"
                          :disabled="loading"
                        >
                            Send
                        </v-btn>
                        <v-btn color="grey darken-1" text @click="dialog = false" :disabled="loading">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapState, mapGetters } from 'vuex'
export default {
	data() {
		return {
			// helpers
			id: null,
			entity: null,
			dialog: false,

			title: null,

			loading: false,
			rules,

			isDeletionRequest: false,

			contactInfoTypes: [
				{ id: 0, name: 'Phone' },
				{ id: 1, name: 'Landline' },
				{ id: 2, name: 'Email' },
				{ id: 3, name: 'Facebook' },
				{ id: 4, name: 'Twitter' },
				{ id: 5, name: 'WhatsApp' },
				{ id: 6, name: 'Telegram' },
				{ id: 7, name: 'LinkedIn' },
				{ id: 8, name: 'Website' },
			],

			dirType: 1,
            
			addresses: [],
			addressesCounter: 0,
			contactInfos: [],
			contactCounter: 0,

			// data
			name: null,
			reason: null,
			specialtyId: null
		}
	},

	methods: {
		reset() {
			if (this.name) {
				this.$refs.form.resetValidation();
			};

			this.id = null;
			this.addresses = [];
			this.addressesCounter = 0;
			this.contactInfos = [];
			this.contactCounter = 0;
			this.isDeletionRequest = false;

			this.name = null;
			this.reason = null;
			this.specialtyId = null;
		},

		// addresses
		addAddress() {
			if (this.dirType !== 1 && this.addressesCounter < 1) {
				this.addresses.push(
					{
						id: this.addressesCounter++,
						data: {
							regionId: null,
							workload: null,
							address: null,
						}
					}
				);
			} else if (this.dirType === 1) {
				this.addresses.push(
					{
						id: this.addressesCounter++,
						data: {
							regionId: null,
							workload: null,
							address: null,
						}
					}
				);
			} else {
				const message = 'Sorry, but one address is allowed for pharmacies and centers';
				this.$eventBus.$emit('show-snackbar', message, 'info');
			}
		},

		removeAddress(index) {
			this.addresses.splice(index, 1);
			this.addresses.forEach(item => {
				if (item.id > index) item.id--;
			});
			this.addressesCounter--;
		},

		// contact infos
		addContact() {
			this.contactInfos.push(
				{
					id: this.contactCounter++,
					data: {
						contactInfoType: null,
						value: null,
					}
				}
			);
		},

		removeContact(index) {
			this.contactInfos.splice(index, 1);
			this.contactInfos.forEach(item => {
				if (item.id > index) item.id--;
			});
			this.contactCounter--;
		},

		getContactLabel(contactId) {
			return this.contactInfoTypes.find(contact => contact.id === contactId).name;
		},

		// dialog functions
		addCustomer() {
			this.reset();
			this.dirType = 1;
			this.title = 'creation customer request';
			this.dialog = true;
		},

		editCustomer(id) {
			this.reset();
			this.id = id;
			this.fillData();
			this.title = 'modification customer request';
			this.dialog = true;
		},

		deleteCustomer(id) {
			this.reset();
			this.id = id;
			this.fillData();
			this.isDeletionRequest = true;
			this.title = 'Deletion customer request';
			this.dialog = true;
		},

		fillData() {
			this.entity = this.getEntityById(this.id);

			this.name = this.entity.name;

			this.specialtyId = this.entity.specialtyIds[0];

			this.dirType = this.entity.entityType;
            
			this.entity.entityAddresses.forEach(address => {
				this.addresses.push(
					{
						id: this.addressesCounter++,
						data: {
							regionId: address.regionId,
							workload: address.workloadId,
							address: address.address,
						}
					}
				);
			})

			this.entity.contactInfos.forEach(contactInfo => {
				this.contactInfos.push(
					{
						id: this.contactCounter++,
						data: {
							contactInfoType: contactInfo.contactInfoType,
							value: contactInfo.value,
						}
					}
				);
			})
		},

		submit() {
			if (this.$refs.form.validate()) {
				this.loading = true;

				// Deletion / modification customer request
				if (this.id) {
					this.$store.dispatch('entities/modify', {
						// requestType = 1 modification, 2 Deletion
						requestType: this.isDeletionRequest ? 2 : 1,
						entityId: this.entity.id,
						entityType: this.dirType,
						name: this.name,
						reason: this.reason,
						specialtyId: this.specialtyId,
						entityAddresses: this.addresses.map(c => c.data),
						contactInfos: this.contactInfos.map(c => c.data)
					})
						.then(() => {
							this.$eventBus.$emit('show-snackbar', 'The request is sent');
							this.dialog = false;
						})
						.finally(() => {
							this.loading = false;
						})
				} else {
					// creation customer request
					this.$store.dispatch('entities/modify', {
						// requestType = 0 creation
						requestType: 0,
						entityId: null,
						entityType: this.dirType,
						name: this.name,
						reason: this.reason,
						specialtyId: this.specialtyId,
						entityAddresses: this.addresses.map(c => c.data),
						contactInfos: this.contactInfos.map(c => c.data)
					})
						.then(() => {
							this.$eventBus.$emit('show-snackbar', 'The request is sent');
							this.dialog = false;
						})
						.finally(() => {
							this.loading = false;
						})
				}
			}
		}
	},

	computed: {
		...mapState({
			regions: state => state.regions.regions,
			entities: state => state.entities.entities,
			specialties: state => state.specialties.specialties,
			workloads: state => state.workloads.workloads,
		}),

		...mapGetters({
			getEntityById: 'entities/getEntityById'
		})
	}
}
</script>

<style>

</style>