<template>
	<v-app>
		<navbar @drawer="drawer = true" :loading="navbarLoading" v-if="authenticated"></navbar>
		<navigation-drawer v-model="drawer" v-if="authenticated"></navigation-drawer>
		<v-main>
			<router-view
				v-if="authenticated || $route.name === 'login'"
				@loading:active="navbarLoading = true"
				@loading:disable="navbarLoading = false"
			/>
			<loader height="100%" v-if="!authenticated && $route.name !== 'login'"></loader>
		</v-main>
		<footer-navigation v-if="authenticated"></footer-navigation>

		<v-snackbar
			v-model="snackbar"
			app
			transition="slide-y-reverse-transition"
			:color="stateColor"
			content-class="text-body-1"
		>
            {{ message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
	</v-app>
</template>

<script>
import Navbar from './components/Navbar'
import NavigationDrawer from './components/NavigationDrawer'
import FooterNavigation from './components/FooterNavigation'
import Loader from './components/Loader'
import { mapState } from 'vuex'
export default {
	name: 'App',

	data() {
		return {
			drawer: false,
			navbarLoading: false,

			snackbar: false,
			messageState: false,
			message: null,
		}
	},

	watch: {
		'$route.name' () { this.navbarLoading = false; }
	},

	computed: {
		...mapState({
			authenticated: state => state.auth.authenticated
		}),

		stateColor() {
			switch (this.messageState) {
			case 'info': return 'primary';
			case 'success': return 'success';
			case 'error': return 'red lighten-1';
			default: return '';
			}
		}
	},

	components: {
		Navbar,
		NavigationDrawer,
		FooterNavigation,
		Loader
	},

	created() {
		this.$eventBus.$on('show-snackbar', (message, messageState) => {
			this.message = message;
			this.messageState = messageState || 'success';
			this.snackbar = true;
		})
	}
}
</script>

<style lang="scss">
@import './scss/vuetify-overrides.scss';
@media screen and (min-width: 600px) {
	*::-webkit-scrollbar{
		height: 4px;
		width: 4px;
		background-color: #f8f8f8;
	}
	*::-webkit-scrollbar-thumb{
		background-color: #aaaa;
		border-radius: 10px;
	}
}
.beetronix{
    font-size: .8rem;
    font-weight: bolder;
}
</style>