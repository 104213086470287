import { entityType } from './enums';

export function getEntityNameByType (type) {
	switch (type) {
	case entityType.doctor: return 'doctor';
	case entityType.center: return 'center';
	case entityType.pharmacy: return 'pharmacy';
	default: return null;
	}	
}

export function isTouchDevice() {
	return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
}