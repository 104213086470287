import * as types from '../mutation-types'

export default {
	namespaced: true,
    
	state: {
		exam: {}
	},

	actions: {
		fetchAll({ commit }) {
			return window.axios.get('/exams/next')
				.then((response) => {
					commit(types.STORE_EXAM, response.data);
					return response.data;
				})
		},
        
		reset({ commit }) {
			commit(types.RESET_EXAM);
		}
	},

	mutations: {
		[types.STORE_EXAM](state, exam) {
			state.exam = exam;
		},

		[types.RESET_EXAM](state) {
			state.exam = {};
		},
	}
}