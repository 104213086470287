<template>
	<div>
		<div>
			<p class="price text-body-1">
				<span class="price-value">{{ getMaterialById(material.id).name }}</span>
				<v-spacer />
				<span v-if="material.price" class="secondary--text">
					<span class="me-1"> {{ material.price }} SYP </span>
				</span>
			</p>
		</div>

		<div class="d-flex align-center">
			<!-- card -->
			<v-card
				outlined
				max-width="90%"
				class="product-card mx-auto"
				v-ripple="{ class: 'product-ripple' }"
				:disabled="disabled"
				:href="material.leafletFilePath"
				target="_blank"
			>
				<v-card-text class="pa-1">
					<v-row dense class="ma-0 align-center">
						<v-col sm="12" cols="5" class="pe-sm-0 pe-3">
							<v-img
								:src="
									material.imageFilePath
										? material.imageFilePath
										: require('@/assets/imgs/img-placeholder.png')
								"
								max-width="250"
								min-width="120"
								class="rounded mx-auto my-auto"
							/>
						</v-col>
						<v-col sm="12" cols="7" class="d-flex flex-column justify-center">
							<div
								class="d-flex align-center"
								:class="{ 'justify-center': $vuetify.breakpoint.smAndUp }"
							>
								<v-icon small color="primary" class="me-2">mdi-hubspot</v-icon>
								<span class="primary--text">Pharmaceutical Form</span>
							</div>
							<div
								class="ms-6"
								:class="{ 'text-center': $vuetify.breakpoint.smAndUp }"
							>
								{{ getMaterialFormById(material.materialFormId).name }}
							</div>

							<template v-if="false">
								<div
									class="d-flex align-center"
									:class="{ 'justify-center': $vuetify.breakpoint.smAndUp }"
								>
									<v-icon small color="primary" class="me-2">mdi-flask</v-icon>
									<span class="primary--text">Dose</span>
								</div>
								<div
									class="ms-6"
									:class="{ 'text-center': $vuetify.breakpoint.smAndUp }"
								>
									{{ material.dose }}
								</div>
							</template>

							<div
								class="d-flex align-center"
								:class="{ 'justify-center': $vuetify.breakpoint.smAndUp }"
							>
								<v-icon small color="primary" class="me-2">mdi-pill</v-icon>
								<span class="primary--text">Pieces Count</span>
							</div>
							<div
								class="ms-6"
								:class="{ 'text-center': $vuetify.breakpoint.smAndUp }"
							>
								{{ material.pillsPerBox }}
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	props: ['disabled', 'material'],

	data() {
		return {
			snackbar: false,
			message: null,
		};
	},

	computed: {
		...mapGetters({
			getMaterialById: 'materials/getMaterialById',
			getMaterialFormById: 'materialForms/getMaterialFormById',
		}),
	},
};
</script>

<style lang="scss">
@import '../scss/vars.scss';
.product-card {
	transition: transform 0.4s ease-in-out;
	overflow: hidden;
}
.product-ripple {
	color: rgba($primary, 0.5);
}
.price {
	display: flex;
	margin-bottom: 0.5rem;
	align-items: center;
	position: relative;

	&-value {
		display: flex;
		align-items: center;
		margin-left: 22px;

		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			left: -18px;
			width: 30px;
			height: 8px;
			border-radius: 10px;

			background-color: $secondary;
		}
	}
}
</style>