<template>
    <!-- card -->
    <v-card
        outlined
        max-width="95%"
        class="visit-card mx-auto"
    >
        <v-card-text>
            <!-- samples -->
            <v-row class="justify-space-between align-center">
                <v-col cols="9">
                    <span class="text-capitalize primary--text text--darken-1 text-subtitle-2">
                        {{ getFullMaterialName(getMaterialById(sample.materialId)) }}
                    </span>
                </v-col>
                <v-col cols="3" class="text-center">{{sample.remaining.amount}}</v-col>
            </v-row>
        </v-card-text>

    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	props: [
		'sample'
	],

	methods: {
		getFullMaterialName(material) {
			const details = [
				this.getMaterialFormById(material.materialFormId).name,
				material.dose,
				material.strength
			];

			return `${material.name} (${details.filter(c => c != null).join(' ')})`;
		},
	},

	computed: {
		...mapGetters({
			getMaterialById: 'materials/getMaterialById',
			getMaterialFormById: 'materialForms/getMaterialFormById',
		})
	},
}
</script>

<style>

</style>