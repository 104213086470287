<template>
    <div>
        <div>
            <p class="direction text-body-1">
                <span class="direction-name">{{ customer.name }}</span>
                <v-spacer/>
                <span v-if="'visit.entitySpecialty.name'" class="secondary--text">
                    <span class="me-1" v-if="customer.specialtyIds">
                        {{getSpecialties(customer.specialtyIds)}}
                    </span>
                    <span v-if="customer.entityAddresses" class="me-1">
                        {{getWorkloadById(customer.entityAddresses[0].workloadId)?.name}}
                    </span>
                </span>
            </p>
        </div>

        <div class="d-flex align-center">
            <!-- deleteFab -->
            <v-fab-transition>
                <v-btn fab absolute left v-show="deleteFab" dark small text color="red darken-2">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-fab-transition>

            <!-- card -->
            <v-card
              outlined
              width="90%"
              class="customer-card mx-auto"
              v-ripple="{class:'customer-ripple'}"
              v-touch.sync="{
                move: (e) => { touchMove(e) },
                end: (e) => { endOffset(e) }
              }"
              :style="'transform: translateX('+ touchSwipeOffset +'px)'"
              :disabled="disabled"
            >
                <v-card-text @click="$emit('on-click')" style="position: relative">
                    <!-- Address -->
                    <div v-if="customer.entityAddresses">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-map-marker-outline</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">Address</span>
                        </div>
                        <div class="ms-5">
                            {{getRegionById(customer.entityAddresses[0].regionId)?.name}} - 
                            {{ customer.entityAddresses[0].address }}
                        </div>
                    </div>

                    <!-- Contanct info -->
                    <div v-if="customer.contactInfos.length > 0" class="mt-1">
                        <div>
                            <v-icon small class="primary--text text--lighten-2 me-1">mdi-card-text-outline</v-icon>
                            <span class="text-capitalize primary--text text--darken-1">Contanct info</span>
                        </div>
                        <div class="ms-5">
                            <div v-for="(contactInfo, index) in customer.contactInfos" :key="index">
                                <v-icon x-small>{{getIcon(contactInfo.contactInfoType)}}</v-icon>
                                {{getContactInfoType(contactInfo.contactInfoType)}}: {{contactInfo.value}}
                            </div>
                        </div>
                    </div>
                </v-card-text>
                
                <v-card-actions class="pt-0" v-if="!isTouchDevice">
                    <v-spacer/>
                    <v-btn
                        text
                        color="red lighten-1"
                        @click="deleteCustomer(customer.id)"
                    >
                        delete
                    </v-btn>
                    <v-btn
                        text
                        color="success"
                        @click="editCustomer(customer.id)"
                    >
                        edit
                    </v-btn>
                </v-card-actions>
            </v-card>

            <!-- EditFab -->
            <v-fab-transition>
                <v-btn fab absolute right v-show="EditFab" dark small text color="success darken-2">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </v-fab-transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	props: [
		'disabled',
		'customer'
	],

	data() {
		return {
			touchSwipeOffset: 0,
			maxOffsetAllowed: 30,
			isTouchDevice: true,

			snackbar: false,
			message: null,

			deleteFab: false,
			EditFab: false,
		}
	},

	methods: {
		// touch methods
		endOffset(e) {
			setTimeout(() => {
				if (this.touchSwipeOffset === this.maxOffsetAllowed) {
					this.deleteCustomer(this.customer.id);
				} else if (this.touchSwipeOffset === -this.maxOffsetAllowed) {
					this.editCustomer(this.customer.id);
				}
				this.deleteFab = false;
				this.EditFab = false;
				this.touchSwipeOffset = 0;
			}, 200);
		},

		touchMove(e) {
			const offset = e.touchmoveX - e.touchstartX;
			this.touchSwipeOffset = offset > -this.maxOffsetAllowed && offset < this.maxOffsetAllowed ? offset : Math.sign(offset) * this.maxOffsetAllowed;

			if (this.touchSwipeOffset === this.maxOffsetAllowed) {
				this.deleteFab = true;
				this.EditFab = false;
			} else if (this.touchSwipeOffset === -this.maxOffsetAllowed) {
				this.deleteFab = false;
				this.EditFab = true;
			} else {
				this.deleteFab = false;
				this.EditFab = false;
			}
		},
        
		// component methods
		getIcon(contactInfoType) {
			switch (contactInfoType) {
			case 0 : return 'mdi-phone';
			case 1 : return 'mdi-phone-classic';
			case 2 : return 'mdi-email';
			case 3 : return 'mdi-facebook';
			case 4 : return 'mdi-twitter';
			case 5 : return 'mdi-whatsapp';
			case 6 : return 'mdi-message-text';
			case 7 : return 'mdi-linkedin';
			case 8 : return 'mdi-earth';
			}
		},

		getContactInfoType(contactInfoType) {
			switch (contactInfoType) {
			case 0 : return 'Phone';
			case 1 : return 'Landline';
			case 2 : return 'Email';
			case 3 : return 'Facebook';
			case 4 : return 'Twitter';
			case 5 : return 'WhatsApp';
			case 6 : return 'Telegram';
			case 7 : return 'LinkedIn';
			case 8 : return 'Website';
			}
		},

		editCustomer(customerId) {
			this.$parent.$refs.CustomerDialog.editCustomer(customerId);
		},

		deleteCustomer(customerId) {
			this.$parent.$refs.CustomerDialog.deleteCustomer(customerId);
		},

		getSpecialties(specialtyIds) {
			const specialties = []
			specialtyIds.forEach(id => specialties.push(this.getSpecialtyById(id).name));
			return specialties.join(' ,');
		}
	},

	computed: {
		...mapGetters({
			getSpecialtyById: 'specialties/getSpecialtyById',
			getWorkloadById: 'workloads/getWorkloadById',
			getRegionById: 'regions/getRegionById',
		})
	},

	mounted() {
		this.isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
	}
}
</script>

<style lang="scss">
@import '../scss/vars';
.customer-card{
    transition: transform .4s ease-in-out;
    overflow: hidden;
}
.customer-ripple{
    color: rgba($primary, .5);
}
.direction{
    display: flex;
    margin-bottom: .5rem;
    align-items: center;
    position: relative;
    
    &-name{
        display: flex;
        align-items: center;
        margin-left: 22px;

        &::before{
            content: '';
            display: inline-block;
            position: absolute;
            left: -18px;
            width: 30px;
            height: 8px;
            border-radius: 10px;

            background-color: $secondary;
        }
    }
}
</style>