<template>
    <div class="loader" :style="'height:'+ height">
        <v-progress-circular
            indeterminate
            size="40"
            color="secondary"
        ></v-progress-circular>
    </div>
</template>

<script>
export default {
	props: [
		'height',
	]
}
</script>

<style lang='scss'>
.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45vh;
}
</style>