<template>
    <v-navigation-drawer
      v-model="isOpenDrawer"
      @input="$emit('update:drawer', drawerState)"
      app
      fixed
      left
      temporary
    >
        <!-- username -->
        <v-list>
            <v-list-item>
                <v-list-item-avatar rounded="0">
                    <v-img contain src="@/assets/logo.jpg"/>
                </v-list-item-avatar>
                <v-list-item-title class="text-h6 font-weight-bold primary--text">
                    {{ userData.name }}
                </v-list-item-title>
            </v-list-item>
        </v-list>

        <v-divider/>

        <!-- links list -->
        <v-list
            nav
            dense
        >
            <v-list-item-group color="primary">
                <!-- Visits -->
                <v-list-item :to="{name: 'visits'}" exact>
                    <v-list-item-icon>
                        <v-icon>mdi-medical-bag</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-subtitle-2">Visits</v-list-item-title>
                </v-list-item>
                
                <!-- Visits frequency -->
                <v-list-item :to="{name: 'visits-frequency'}" exact>
                    <v-list-item-icon>
                        <v-icon>mdi-chart-waterfall mdi-rotate-90</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-subtitle-2">Visits frequency</v-list-item-title>
                </v-list-item>

                <!-- Plans -->
                <v-list-item :to="{name: 'plans'}" exact>
                    <v-list-item-icon>
                        <v-icon>mdi-notebook-edit-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-subtitle-2">Plans</v-list-item-title>
                </v-list-item>

                <!-- Customers -->
                <v-list-item :to="{name: 'customers'}" exact>
                    <v-list-item-icon>
                        <v-icon>mdi-home-city-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-subtitle-2">Customers</v-list-item-title>
                </v-list-item>

                <!-- Samples -->
                <v-list-item :to="{name: 'samples'}" exact>
                    <v-list-item-icon>
                        <v-icon>mdi-pill</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-subtitle-2">Samples</v-list-item-title>
                </v-list-item>

                <!-- Products -->
                <v-list-item :to="{name: 'products'}" exact>
                    <v-list-item-icon>
                        <v-icon>mdi-palette-swatch</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-subtitle-2">Products</v-list-item-title>
                </v-list-item>

                <!-- Profile -->
                <v-list-item  :to="{name: 'profile'}" exact>
                    <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-subtitle-2">Profile</v-list-item-title>
                </v-list-item>

                <!-- Exams -->
                <v-list-item  :to="{name: 'exams'}" exact>
                    <v-list-item-icon>
                        <v-icon>mdi-text-box-check-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-subtitle-2">Exams</v-list-item-title>
                </v-list-item>

                <!-- Logout -->
                <v-list-item @click="logout" exact>
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-subtitle-2">Logout</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>

        <!-- powered by -->
        <template v-slot:append>
            <div class="text-overline ms-5 mb-3">
                v{{VERSION}} <br />
                powered by
				<a
					href="https://www.beetronix.com"
					target="_blank"
					class="beetronix text-uppercase text-decoration-none primary--text"
				>Beetronix</a>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { VERSION } from '@/helpers/constants'
import { mapState } from 'vuex'
export default {
	model: {
		prop: 'drawer',
		event: 'update:drawer'
	},

	props: {
		drawer: { default: false }
	},

	data() {
		return {
			drawerState: false,
			VERSION,
		}
	},

	computed: {
		isOpenDrawer: {
			get() {
				return this.drawer;
			},

			set(newValue) {
				this.drawerState = newValue;
			}
		},

		...mapState({
			userData: state => state.auth.userData
		})
	},

	methods: {
		logout() {
			this.$store.dispatch('auth/logout');
			this.$router.push({ name: 'login' });
		}  
	}
}
</script>